import React from 'react';
import PropTypes from 'prop-types';

const OutcomeData = (props) => {
  const {
    data,
  } = props;

  const outcomes = () => {
    let outcomeAll = data.outcomes.map((outcome) => {
      if (outcome.type === 'Other') outcome.type = 'Other Outcome';
      const displayNames = { date: `${outcome.type} Date`, citation: 'Citation', description: 'Description', title: `${outcome.type} Title`, authors: 'Author(s) Name(s)', journal: 'Journal Name', publicationURL: 'Publication URL', presentedTo: 'Presented To', patentApplicationNumber: 'Patent Application Number', patentNumber: 'Patent Number' };
      const keys = Object.keys(outcome);
      // eslint-disable-next-line no-nested-ternary
      keys.sort((x, y) => { return x === 'title' ? -1 : y === 'title' ? 1 : 0; }); // bring title key to front
      const individualOutcome = keys.map((key) => {
        let displayKey = key;
        if (outcome.type === 'Publication' && key === 'description') { displayKey = 'citation'; }
        if (key !== 'type' && key !== 'id' && key !== 'source' && key !== 'requests') {
          return (
            <div className="row">
              <div className="col-xs-12 col-sm-1 py-2" />
              <div className="col-xs-12 col-sm-3 py-2"><b>{displayNames[displayKey]}</b></div>
              <div className="col-xs-12 col-sm-8 py-2">{outcome[key]}</div>
            </div>
          );
        }
      });
      return (
        <div id="study-information">
          <h3 className="form-heading-3">{outcome.type}</h3>
          <div>{individualOutcome}</div>
        </div>
      );
    });
    if (data.outcomes.length === 0) {
      outcomeAll = (<div>None</div>);
    }
    return <div>{outcomeAll}</div>;
  };

  return (
    outcomes()
  );
};

OutcomeData.propTypes = {
  data: PropTypes.object,
  displayOutcomes: PropTypes.bool.isRequired,
};

export default OutcomeData;
