import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ALERT_TYPES } from '../../types';
import AdminInstitutionForm from '../../form/forms/admin/AdminInstitutionForm';
import { renderInstitutionBackendErrors } from '../../form/forms/institution/InstitutionValidation';
import { updateStatus, updateLocation } from '../../../features/admin/AdminActions';
import { submitFormSync } from '../../../features/submission/SubmissionActions';
import Alert from '../../alert';
import { scrollToTopOfDiv } from '../../utils/utils';

const AdminViewInstitutionModal = (props) => {
  const {
    isOpen,
    closeModal,
    initialValues,
    institutionTypes,
    statuses,
  } = props;

  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  const handleSubmit = (values) => {
    props.setLoading(true);
    Promise.all([
      props.updateStatus(values.status, initialValues.id, 'institution'),
      props.updateLocation(values, initialValues.id, 'institution'),
    ])
      .then(() => {
        setAlertType(ALERT_TYPES.SUCCESS);
        setAlertMessage('Institution has been sucessfully updated');
        scrollToTopOfDiv('modal-header');
        props.setLoading(false);
      })
      .catch((resp) => {
        setAlertType(ALERT_TYPES.ERROR);
        setAlertMessage(renderInstitutionBackendErrors(resp));
        scrollToTopOfDiv('modal-header');
        props.setLoading(false);
      });
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={closeModal}
        size="lg"
        backdrop="static"
      >
        <ModalHeader id="modal-header" toggle={closeModal} tag="h2">
          Manage Institution
        </ModalHeader>
        <ModalBody>
          <Alert
            alertType={alertType}
            message={alertMessage}
            allowClose={false}
          />
          <AdminInstitutionForm
            toggleInfo
            initialValues={initialValues}
            statuses={statuses}
            institutionTypes={institutionTypes}
            onSubmit={handleSubmit}
          />
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn modal-btn btn-secondary"
            onClick={closeModal}
          >
            Close
          </button>
          {alertType !== ALERT_TYPES.SUCCESS && (
            <button
              type="submit"
              className="btn modal-btn modal-btn-blue"
              onClick={() => props.submitFormSync('adminInstitutionForm')}
            >
              Submit
            </button>
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
};

AdminViewInstitutionModal.propTypes = {
  initialValues: PropTypes.object,
  submitFormSync: PropTypes.func,
  closeModal: PropTypes.func,
  isOpen: PropTypes.bool,
  statuses: PropTypes.array,
  setLoading: PropTypes.func,
  updateStatus: PropTypes.func,
  updateLocation: PropTypes.func,
  institutionTypes: PropTypes.array,
};

const actions = { submitFormSync, updateStatus, updateLocation };
export default connect(null, actions)(AdminViewInstitutionModal);
