import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { FileUpload } from '../../../../../common/inputs';
import { validateUploadExecutedAgreementForm } from './AdminRequestValidator';

export const UploadExecutedAORModificationForm = props => (
  <form onSubmit={props.handleSubmit}>
    <div>
      Please upload the Executed AOR Modification Form file for this request.
    </div>
    <div className="form-group p-15">
      <Field
        component={FileUpload}
        name="uploadRequestFileForm"
        id="executed-aor-modification-form"
        label="Executed AOR Modification Form"
        required
      />
    </div>
  </form>
);

UploadExecutedAORModificationForm.propTypes = {
  handleSubmit: PropTypes.func,
};

export default reduxForm({
  form: 'uploadExecutedAORModificationForm',
  validate: validateUploadExecutedAgreementForm,
})(UploadExecutedAORModificationForm);
