import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from '../../../../../../img/icons/CloseIcon.svg';
import { ClearButton } from '../../../../../../common/buttons';

const AffiliateUser = ({ user, remove }) => (
  <div className="row selected-affiliate align-items-center">
    <div className="col-10 col-lg-6 affiliate-info">
      <span>{user.name}</span>
      <span className="d-none d-sm-block">&nbsp;({user.emailAddress})</span>
    </div>
    <div className="col-2 col-lg-6">
      <ClearButton
        icon={<CloseIcon height="20px" width="20px" title="Close Button"/>}
        onClick={() => remove(user.id)}
        ariaLabel={`Remove ${user.name}`}
      />
    </div>
  </div>
);

AffiliateUser.propTypes = {
  user: PropTypes.shape({
    emailAddress: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  remove: PropTypes.func.isRequired,
};

export default AffiliateUser;
