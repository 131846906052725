import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { MODAL_TYPES } from '../../../common/types';

const ChallengeStudyPromptModal = (props) => {
  const { isOpen, history, openModal, closeModal, handleAddToCart } = props;

  const handleRegisterNow = () => {
    const modalProps = {
      modalHeader: 'Sign Out Warning',
      BodyComponent: () => (
        <div>
          You are about to be signed out. Select Continue to be taken to the
          registration page.
        </div>
      ),
      actionButtonText: 'Continue',
      actionButtonOnClick: () => {
        closeModal();
        history.push('/reregister');
      },
    };
    openModal(MODAL_TYPES.BASIC_MODAL, modalProps);
  };

  return (
    <div>
      <Modal isOpen={isOpen} toggle={closeModal}>
        <ModalHeader toggle={closeModal} tag="h3">
          Message For Data Challenge Participants
        </ModalHeader>
        <ModalBody>
          If you intend to use these items as a participant in NICHD Decoding
          Maternal Morbidity Data Challenge you must first register as a Data
          Challenge Participant, using a different email address. Please click
          the Register Now button below to register as a Data Challenge
          Participant.
        </ModalBody>
        <ModalFooter className="challenge-footer">
          <div className="row">
            <div className="col-sm">
              <button
                type="button"
                className="btn challenge-btn btn-secondary"
                onClick={closeModal}
              >
                Cancel
              </button>
              <div className="row">
                <p>Do not add study item</p>
              </div>
            </div>
            <div className="col-sm">
              <button
                type="button"
                className="btn challenge-btn btn-secondary"
                onClick={() => {
                  closeModal();
                  handleAddToCart();
                }}
              >
                Continue
              </button>
              <div className="row">
                <p className="challenge-span">Request is not related to Challenge</p>
              </div>
            </div>
            <div className="col-sm">
              <button
                type="button"
                className="btn challenge-btn modal-btn-blue"
                onClick={handleRegisterNow}
              >
                Register Now
              </button>
              <div className="row">
                <p>Register now as Challenge Participant</p>
              </div>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

ChallengeStudyPromptModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleAddToCart: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default ChallengeStudyPromptModal;
