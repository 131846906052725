// Base Validation Functions
const required = fieldName => value => (value ? undefined : `Please enter the ${fieldName}`);

const noEmptyString = fieldName => value => (value && value.trim() === '' ? `${fieldName} may not be blank` : undefined);

const nameExceedsMaxLength = fieldName => value => (value && value.trim().length > 32 ? `${fieldName} may not be longer than 32 characters` : undefined);

const textExceedsMaxCount = fieldName => value => (value && value.trim().length > 256 ? `${fieldName} may not be longer than 256 characters` : undefined);

// Field Valdiations

// Email validation
export const validateEmailFormat = value => (value && !/^[^@]+@[^@]+\.[^@]+$/.test(value) ? 'Please enter a correct email format' : undefined);

// Max length check
export const firstNameExceedsMaxLength = nameExceedsMaxLength('First Name');

export const lastNameExceedsMaxLength = nameExceedsMaxLength('Last Name');

export const jobTitleExceedsMaxLength = textExceedsMaxCount('Job Title');

export const projectRoleExceedsMaxLength = textExceedsMaxCount('Project Role');

export const emailExceedsMaxLength = textExceedsMaxCount('Email Address');

// Empty string check
export const noEmptyStringFirstName = noEmptyString('First Name');

export const noEmptyStringLastName = noEmptyString('Last Name');

export const noEmptyStringJobTitle = noEmptyString('Job Title');

export const noEmptyStringProjectRole = noEmptyString('Project Role');

export const noEmptyStringEmailAddress = noEmptyString('Email Address');

// Unentered field check
export const requiredFirstName = required('First Name');

export const requiredLastName = required('Last Name');

export const requiredInstitution = required('Institution');

export const requiredJobTitle = required('Job Title');

export const requiredProjectRole = required('Project Role');

export const requiredEmail = required('Email Address');
