import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { RequiredTag } from '../../other/RequiredFields';
import './style.scss';

const InputBox = (props) => {
  const {
    input,
    id,
    className,
    label,
    disabled,
    required,
    tooltip,
    meta,
    ariaLabel,
    placeholder,
    ...rest
  } = props;

  let errorClassName = '';
  let errorMessageClassName = 'c-validation_error';
  let errorMessage = null;
  if (!disabled && meta && meta.touched && (meta.error || meta.warning)) {
    errorClassName = 'validation-error mb-0';
    errorMessageClassName = 'c-validation_error mb-9';
    errorMessage = `${meta.error || meta.warning}`;
  }

  return (
    <div>
      { label && (
        <label htmlFor={id}>
          {label}
          &nbsp;
          {required && <RequiredTag />}
        </label>
      )}
      <input
        {...input}
        {...rest}
        id={id}
        className={`form-control ${className} ${errorClassName}`}
        disabled={disabled}
        aria-label={ariaLabel || placeholder}
        data-tip
        data-for={tooltip && tooltip.id}
        placeholder={props?.placeholder}
      />
      <div className={errorMessageClassName}>
        {errorMessage}
      </div>
      { tooltip && (
        <ReactTooltip id={tooltip.id} place="bottom" type="info" effect="float">
          <span>{tooltip.message}</span>
        </ReactTooltip>
      )}
    </div>
  );
};

InputBox.propTypes = {
  id: PropTypes.string.isRequired,
  input: PropTypes.object,
  className: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  ariaLabel: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  tooltip: PropTypes.shape({
    id: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
  }),
};

InputBox.defaultProps = {
  type: 'text',
  disabled: false,
  required: false,
  className: '',
};

export default InputBox;
