import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { YellowButton } from '../../buttons';

export const CollapseSection = (props) => {
  const { introText, Component, note, ...rest } = props;

  const [toggle, setToggle] = useState(false);

  const section = `${props.researcher}${props.researcher === 'Affiliates' ? '' : 's'}`;

  return (
    <div id={`${props.researcher}-section`}>
      <h2 className="form-heading-1">{`${section}`}</h2>
      <hr />
      <p className="package-instructions">{introText}</p>
      {note && (
        <p>
          <strong>Note:&nbsp;</strong>
          {note}
        </p>
      )}
      <div className="row align-items-center justify-content-center">
        <YellowButton
          type="button"
          onClick={() => setToggle(!toggle)}
          label={`Manage ${section}`}
          align="right"
          ariaLabel={toggle ? `Manage ${section}, expanded` : `Manage ${section}, collapsed`}
        />
      </div>
      <div className="row">{toggle && <Component {...rest} />}</div>
    </div>
  );
};

CollapseSection.propTypes = {
  introText: PropTypes.string,
  researcher: PropTypes.string.isRequired,
  Component: PropTypes.object,
  note: PropTypes.string,
};

export default CollapseSection;
