import { GET_VERSION_NUMBER } from '../types';

const INITIAL_STATE = {
  version: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_VERSION_NUMBER:
      return { ...state, version: action.payload };
    default:
      return state;
  }
};
