import React, { useEffect, useState } from 'react';
import { FormSection, reduxForm, Field } from 'redux-form';
import PropTypes from 'prop-types';
import AddressForm from '../address/AddressForm';
import { validateDivision } from './DivisionValidation';
import { Checkbox, InputBox } from '../../../inputs';
import Alert from '../../../alert';
import { ALERT_TYPES } from '../../../types';

const DivisionForm = ({ change, country, error, institutionId, getInstitutionInfo }) => {
  const [instInfo, setInstInfo] = useState({});

  useEffect(() => {
    getInstitutionInfo(institutionId).then((res) => {
      setInstInfo(res);
    });
  }, []);

  // change the division name to the institution name if the user selects the 'Use My Institution Name' checkbox
  const handleUseInstNameOnChange = (e, newValue, prevValue) => {
    const useInstName = !prevValue;
    if (useInstName) {
      change('name', instInfo.name);
    } else {
      change('name', null);
    }
    change('division.useInstName', useInstName);
  };

  const handleUseInstAddressOnChange = (e, newValue, prevValue) => {
    const useInstAddress = !prevValue;
    if (useInstAddress && instInfo.address) {
      change('address.country', instInfo.address.country);
      change('address.address1', instInfo.address.address1);
      change('address.address2', instInfo.address.address2);
      change('address.city', instInfo.address.city);
      change('address.state', instInfo.address.state);
      change('address.zip', instInfo.address.zip);
    } else {
      change('address.country', null);
      change('address.address1', null);
      change('address.address2', null);
      change('address.city', null);
      change('address.state', null);
      change('address.zip', null);
    }
    change('useInstAddress', useInstAddress);
  };

  return (
    <form>
      <Alert alertType={ALERT_TYPES.ERROR} message={error} />
      <div className="form-group">
        <div className="row">
          <div className="col-12 col-md-6 p-15">
            <Field
              component={Checkbox}
              id="division.useInstName"
              name="division.useInstName"
              label="Use Institution Name"
              onChange={handleUseInstNameOnChange}
            />
          </div>
          <div className="col-12 col-md-6 p-15">
            <Field
              component={Checkbox}
              id="division.useInstAddress"
              name="divison.useInstAddress"
              label="Use Institution Address"
              onChange={handleUseInstAddressOnChange}
            />
          </div>
        </div>
        <Field
          component={InputBox}
          id="name"
          name="name"
          label="School/Division/Center"
          placeholder="School/Division/Center"
          required
        />
      </div>
      <FormSection name="address">
        <AddressForm selectedCountry={country} />
      </FormSection>
    </form>
  );
};

DivisionForm.propTypes = {
  change: PropTypes.func.isRequired,
  country: PropTypes.string,
  error: PropTypes.string,
  institutionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  getInstitutionInfo: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'divisionForm',
  validate: validateDivision,
})(DivisionForm);
