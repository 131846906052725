import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import ReactGA from 'react-ga4';
import store, { history } from './app/store/store';
import './index.scss';
import App from './app/App';

const MOUNT_NODE = document.getElementById('root');
const userId = localStorage.getItem('id');
const accountNumber = (window.location.origin === 'https://dash.nichd.nih.gov') ? 'G-3E3XKSS7XP' : 'G-WNPZ529F6E';

ReactGA.initialize(accountNumber, { gaOptions: {
    cookieFlags: 'SameSite=Strict;Secure',
    userId
} });

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>, MOUNT_NODE
);
