import React, { useState } from 'react';
import { FormSection, reduxForm, Field } from 'redux-form';
import { Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import AddressForm from '../address/AddressForm';
import { validateDivision } from '../division/DivisionValidation';
import { InputBox, DropdownSelect } from '../../../inputs';
import Alert from '../../../alert';
import { ALERT_TYPES } from '../../../types';
import { ReactComponent as DownArrowIcon } from '../../../../img/icons/arrows/Down.svg';
import { ReactComponent as UpArrowIcon } from '../../../../img/icons/arrows/Up.svg';

export const AdminDivisionForm = (props) => {
  const { error, disableFields, statuses, showInstitution } = props;
  const [showInfo, toggleShowInfo] = useState(false);
  return (
    <form>
      <Alert
        alertType={ALERT_TYPES.ERROR}
        message={error}
      />
      {showInstitution && (
        <Field
          component={InputBox}
          id="institution-name"
          name="institution.name"
          label="Institution"
          placeholder="Institution"
          required
          disabled={disableFields}
        />
      )
      }
      <Field
        component={InputBox}
        id="division-name"
        name="name"
        label="School/Division/Center"
        placeholder="School/Division/Center"
        required
        disabled={disableFields}
      />
      <button
        type="button"
        className="p-0 fs-14 mr-0 ml-auto d-block"
        onClick={() => toggleShowInfo(!showInfo)}
        aria-expanded={showInfo}
        aria-controls="admin-division-address"
      >
        {!showInfo
          ? <>Show Division Address  <DownArrowIcon height="13px" stroke="black" /></>
          : <>Hide Division Address <UpArrowIcon height="13px" stroke="black" /></>
        }
      </button>
      <Collapse
        id="admin-division-address"
        isOpen={showInfo}
      >
        <FormSection name="address">
          <AddressForm disableFields={disableFields} />
        </FormSection>
      </Collapse>

      {!disableFields && (
        <Field
          component={DropdownSelect}
          id="status"
          label="Status"
          placeholder="Status"
          name="status"
          options={statuses.map(s => ({ value: s, item: s }))}
        />
      )}
    </form>
  );
};

AdminDivisionForm.propTypes = {
  error: PropTypes.string,
  disableFields: PropTypes.bool,
  statuses: PropTypes.array,
  showInstitution: PropTypes.bool,
};

export default reduxForm({
  form: 'adminDivisionForm',
  warn: validateDivision,
})(AdminDivisionForm);
