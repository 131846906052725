import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import AdminUserForm from '../../form/forms/admin/AdminUserForm';
import { ALERT_TYPES } from '../../types';
import { updateStatus, updateRoles, updateUser, toggleUserProperty, getDivisionFormSupport } from '../../../features/admin/AdminActions';
import { submitFormSync } from '../../../features/submission/SubmissionActions';
import Alert from '../../alert';
import { scrollToTopOfDiv } from '../../utils/utils';
import { parseBackendErrors } from '../../../features/profile/ProfileValidation';

const AdminViewUserModal = (props) => {
  const {
    isOpen,
    closeModal,
    initialValues,
    statuses,
    roles,
    formSupport,
    institutionTypes,
    divisions, // From store, if the user changes the institution we need the relevent divisions
  } = props;

  const { requests, submissions } = formSupport;
  const [alert, setAlert] = useState({ alertType: null, alertMessage: null });
  const [requestAlert, setRequestAlert] = useState({ alertType: null, alertMessage: null });
  const [submissionAlert, setSubmissionAlert] = useState({ alertType: null, alertMessage: null });
  const [userUpdated, setUserUpdated] = useState(false);

  const setUpAlerts = () => {
    if (requests && requests.length > 0) {
      const message = (
        <div>
          This user has request(s) and cannot be edited.
          <p>
          Requests ID(s): {requests.map(r => r.requestId).join(', ')}
          </p>
        </div>
      );
      setRequestAlert({ alertType: ALERT_TYPES.WARNING, alertMessage: message });
    } else if (submissions && submissions.length > 0) {
      const message = (
        <div>
          This user belongs to the following submissions.
          <p>
            Submission ID(s): {submissions.map(r => r.id).join(', ')}
          </p>
        </div>
      );
      setSubmissionAlert({ alertType: ALERT_TYPES.INFO, alertMessage: message });
    }
  };

  useEffect(() => {
    setUpAlerts();
  }, []);

  // calling these sequentially so parallel processes in the backend don't save an older version of the user
  const updateUserSequentially = async (values) => {
    const newRoles = Object.keys(values.roles).filter(role => values.roles[role]).map(role => (role));
    try {
      await props.updateRoles(newRoles, initialValues.id);
      if (initialValues.division.id !== values.division.id) {
        await props.updateUser(values, initialValues.id);
        initialValues.division.institution.name = _.find(formSupport.institutions, { id: parseInt(values.division.institution.id, 10) }).name; // Once institution is changed, we need to change initialValue names to reflect this
        initialValues.division.name = _.find(divisions, { id: parseInt(values.division.id, 10) }).name;
      }
      if (initialValues.status !== values.status) {
        await props.updateStatus(values.status, initialValues.id, 'user');
      }
      if (initialValues.optOut !== values.optOut) {
        await props.toggleUserProperty(values.optOut, initialValues.id, 'quarterly-email');
      }
      if (initialValues.isInternalUser !== values.isInternalUser) {
        await props.toggleUserProperty(values.isInternalUser, initialValues.id, 'internal-user');
      }
    } catch (error) {
      throw error;
    }
  };

  const handleSubmit = async (values) => {
    props.setLoading(true);
    updateUserSequentially(values)
      .then(() => {
        setAlert({ alertType: ALERT_TYPES.SUCCESS, alertMessage: 'User has been sucessfully updated' });
        setUserUpdated(true);
        scrollToTopOfDiv('modal-header');
        props.setLoading(false);
      }).catch((error) => {
        const errorMessage = parseBackendErrors('profile', error);
        setAlert({ alertType: ALERT_TYPES.ERROR, alertMessage: errorMessage });
        scrollToTopOfDiv('modal-header');
        props.setLoading(false);
      });
  };

  const getDisabled = () => {
    if (userUpdated) {
      return true;
    }
    if (requests && requests.length > 0) {
      return true;
    }
    if (submissions && submissions.length > 0) { // previously allowed to edit user after alert was cancelled. Changed to never allow.
      return true;
    }
    return false;
  };

  const submissionAlertOnClose = () => {
    setSubmissionAlert({ alertType: null, alertMessage: null });
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={closeModal}
        size="lg"
        backdrop="static"
      >
        <ModalHeader id="modal-header" toggle={closeModal} tag="h2">
          Manage User
        </ModalHeader>
        <ModalBody>
          <Alert
            alertType={alert.alertType}
            message={alert.alertMessage}
            allowClose={false}
          />
          <Alert
            alertType={requestAlert.alertType}
            message={requestAlert.alertMessage}
            allowClose={false}
          />
          <Alert
            alertType={submissionAlert.alertType}
            message={submissionAlert.alertMessage}
            onClose={submissionAlertOnClose}
          />
          <AdminUserForm
            initialValues={initialValues}
            roles={roles}
            statuses={statuses}
            onSubmit={handleSubmit}
            disabled={getDisabled()}
            getDivisionFormSupport={props.getDivisionFormSupport}
            divisions={divisions} // this division is attained from the store if the user changes the institution
            formSupport={formSupport}
            institutionTypes={institutionTypes}
          />
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn modal-btn btn-secondary"
            onClick={closeModal}
          >
            Close
          </button>
          {alert.alertType !== ALERT_TYPES.SUCCESS && (
            <button
              type="submit"
              className="btn modal-btn modal-btn-blue"
              onClick={() => props.submitFormSync('adminUserForm')}
            >
              Submit
            </button>
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
};

AdminViewUserModal.propTypes = {
  initialValues: PropTypes.object,
  submitFormSync: PropTypes.func,
  closeModal: PropTypes.func,
  isOpen: PropTypes.bool,
  statuses: PropTypes.array,
  roles: PropTypes.array,
  setLoading: PropTypes.func,
  updateStatus: PropTypes.func,
  updateRoles: PropTypes.func,
  updateUser: PropTypes.func,
  status: PropTypes.string,
  formSupport: PropTypes.object,
  institutionTypes: PropTypes.array,
  toggleUserProperty: PropTypes.func,
  getDivisionFormSupport: PropTypes.func,
  divisions: PropTypes.array,
};

const mapStateToProps = ({ admin }) => ({
  divisions: admin.user.profile,
});

const actions = { updateRoles, updateStatus, submitFormSync, updateUser, toggleUserProperty, getDivisionFormSupport };
export default connect(mapStateToProps, actions)(AdminViewUserModal);
