import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import './style.scss';

const Checkbox = (props) => {
  const {
    id,
    label,
    input,
    className,
    meta,
    required,
    disabled,
    style,
    tooltip,
    ariaLabel,
    ...rest
  } = props;

  const errorClassNames = !disabled && meta && meta.touched && (meta.error || meta.warning) ? 'validation-error mb-0' : '';
  return (
    <div className={`checkbox-dash ${className}`} style={style}>
      <input
        {...input}
        {...rest}
        checked={input.value}
        id={id}
        type="checkbox"
        className="form-check-input"
        aria-label={ariaLabel ? ariaLabel : label}
        aria-checked={input.value}
        disabled={disabled}
      />
      <label
        htmlFor={id}
        data-tip
        data-for={tooltip && tooltip.id}
      >
        <div className={errorClassNames} />
        { label && (
          <span>
            {label}
            {required && <span className="requiredField m-0">&nbsp;*</span>}
          </span>
        )}
      </label>
      { tooltip && (
        <ReactTooltip id={tooltip.id} place="bottom" type="info" effect="float">
          <span>{tooltip.message}</span>
        </ReactTooltip>
      )}
    </div>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string.isRequired, // this must be a unique id for the component
  input: PropTypes.shape({
    value: PropTypes.bool.isRequired, // must be true if the checkbox is selected, false if checkbox is not selected
    onChange: PropTypes.func.isRequired, // function to change the value of the checkbox
  }).isRequired,
  className: PropTypes.string,
  meta: PropTypes.shape({ // these meta props are used by redux-form to pass validation error props
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
  }),
  label: PropTypes.string, // if label is not provided then pass an aria-label to the component for 508 accessibility
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  tooltip: PropTypes.object,
};

Checkbox.defaultProps = {
  className: '',
  required: false,
  disabled: false,
  style: {},
};

export default Checkbox;
