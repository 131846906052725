import React from 'react';

const BiospecimenRequestGuidelines = () => (
  <>
    <p>Request biospecimens in four quick steps:</p>
    <div className="row">
      <ol>
        <li>Verify that your research is compliant with participants’ consent provisions (if any).</li>
        <li>Complete online biospecimen request form.</li>
        <li>Download biospecimen request package and obtain signatures.</li>
        <li>Upload and submit biospecimen request package.</li>
      </ol>
    </div>
    <span className="requiredField">*</span>&nbsp;
    Note that once your request is approved, the biospecimen repository will contact you to arrange shipping.
  </>
);

export default BiospecimenRequestGuidelines;
