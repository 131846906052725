import {
  AUTH_SUCCESS,
  RAS_REGISTER,
  UNAUTH_SUCCESS,
  SET_AUTH_FLAGS,
  SET_ACTIVITY,
  SET_IGNORE_ACTIVITY,
  RESET_TIMERS,
  SIGN_OUT,
  START_TIMER,
} from '../../common/types';

const INITIAL_STATE = {
  authenticated: false,
  jwt: null,
  refreshToken: null,
  email: null,
  startTime: null,
  curator: false,
  admin: false,
  officer: false,
  isNIHSSO: false,
  serverTimedout: false,
  active: true,
  timer: null,
  ignoreActivity: false,
  id: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTH_SUCCESS:
      return {
        ...state,
        authenticated: true,
        ...action,
      };
    case RAS_REGISTER:
      return {
        ...state,
        data: action.data,
      };
    case UNAUTH_SUCCESS:
      return INITIAL_STATE;
    case SET_AUTH_FLAGS:
      return {
        ...state,
        ...action.payload,
      };
    case SET_ACTIVITY:
      return {
        ...state,
        active: action.payload,
      };
    case SET_IGNORE_ACTIVITY:
      return {
        ...state,
        ignoreActivity: action.payload,
      };
    case RESET_TIMERS:
      return {
        ...state,
        timer: action.timer,
        ignoreActivity: false,
        serverTimedout: false,
        active: true,
        refreshToken: action.refresh_token,
        jwt: action.token,
        startTime: action.startTime,
      };
    case START_TIMER:
      return {
        ...state,
        timer: action.payload,
      };
    case SIGN_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
