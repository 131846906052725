import {
  SEARCH_SET_RESULTS,
  SEARCH_SET_PROPERTIES,
  SEARCH_SET_FACETS,
  SEARCH_SET_CAN_ADD_DATA,
  SEARCH_SET_SELECTED_ITEMS,
  SEARCH_ADD_ITEMS_TO_CART,
  SEARCH_RESET_RESULT,
  SEARCH_RESET_STATE,
} from '../../common/types';

export const initialState = {
  results: null,
  properties: null,
  facets: null,
  canAddData: {},
  selectedItems: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_SET_RESULTS:
      return {
        ...state,
        results: {
          ...state.results,
          ...action.payload.results,
        },
      };
    case SEARCH_SET_PROPERTIES:
      return {
        ...state,
        properties: {
          ...state.properties,
          ...action.payload,
        },
      };
    case SEARCH_SET_FACETS:
      return {
        ...state,
        facets: {
          ...state.facets,
          ...action.payload,
        },
      };
    case SEARCH_SET_CAN_ADD_DATA:
      return {
        ...state,
        canAddData: {
          ...state.canAddData,
          ...action.payload,
        },
      };
    case SEARCH_SET_SELECTED_ITEMS:
      return {
        ...state,
        selectedItems: {
          ...state.selectedItems,
          [action.payload.type]: {
            ...state.selectedItems[action.payload.type],
            ...action.payload.data,
          },
          selectedParentStudies: action.selectedParentStudies,
        },
      };
    case SEARCH_ADD_ITEMS_TO_CART:
      return {
        ...state,
        canAddData: {
          ...state.canAddData,
          [action.payload.type]: {
            ...state.canAddData[action.payload.type],
            ...action.payload.data,
          },
        },
        selectedItems: {
          ...state.selectedItems,
          [action.payload.type]: {
            ...state.selectedItems[action.payload.type],
            ...action.payload.data,
          },
        },
      };
    case SEARCH_RESET_RESULT:
      return {
        ...state,
        results: {
          ...state.results,
          [action.payload]: null,
        },
      };
    case SEARCH_RESET_STATE:
      return {
        ...initialState,
        selectedItems: state.selectedItems,
      };
    default:
      return state;
  }
};
