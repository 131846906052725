import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const ErrorLabel = ({ className, meta }) => {
  const {
    touched,
    error,
    warning,
  } = meta;

  const errorMessage = touched && (error || warning) ? warning || error : '';
  return (
    <div className={`error-label ${className}`}>
      {errorMessage === 'AllItemsAreInvalid' ? 'Please fill in all required fields' : errorMessage}
    </div>
  );
};

ErrorLabel.propTypes = {
  className: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
  }),
};

export default ErrorLabel;
