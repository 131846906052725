import React from 'react';
import PropTypes from 'prop-types';
import { MODAL_TYPES } from '../types';
import BasicModal from './modals/BasicModal';
import ModificationWarningModal from './modals/ModificationWarningModal';
import ConstraintsModal from './modals/ConstraintsModal';
import CreateLocationModal from './modals/CreateLocationModal';
import DownloadDuaModal from './modals/DownloadDuaModal';
import RequestGuidelinesModal from './modals/RequestGuidelinesModal';
import ReuseRequestModal from './modals/ReuseRequestModal';
import AdminViewUserModal from './modals/AdminViewUserModal';
import AdminViewDivisionModal from './modals/AdminViewDivisionModal';
import AdminViewInstitutionModal from './modals/AdminViewInstitutionModal';
import AdminRequestModal from './modals/AdminRequestModal';
import AdminAnnualReportModal from './modals/AdminAnnualReportModal';
import ModifyResearchTeamModal from './modals/ModifyResearchTeamModal';
import DataChallengeSelectionModal from '../../features/dataChallenge/modals/DataChallengeSelectionModal';
import ChallengeStudyPromptModal from '../../features/dataChallenge/modals/ChallengeStudyPromptModal';
import DeleteOutcomeModal from './modals/DeleteOutcomeModal';
import './style.scss';

// json object which maps the MODAL_TYPES to their corresponding React modal component
const modalTypesMap = {
  [MODAL_TYPES.BASIC_MODAL]: BasicModal,
  [MODAL_TYPES.DELETE_OUTCOME_MODAL]: DeleteOutcomeModal,
  [MODAL_TYPES.MODIFICATION_WARNING_MODAL]: ModificationWarningModal,
  [MODAL_TYPES.CONSTRAINTS_MODAL]: ConstraintsModal,
  [MODAL_TYPES.CREATE_LOCATION_MODAL]: CreateLocationModal,
  [MODAL_TYPES.DOWNLOAD_DUA_MODAL]: DownloadDuaModal,
  [MODAL_TYPES.MODIFY_RESEARCH_TEAM_MODAL]: ModifyResearchTeamModal,
  [MODAL_TYPES.REQUEST_GUIDELINES_MODAL]: RequestGuidelinesModal,
  [MODAL_TYPES.REUSE_REQUEST_MODAL]: ReuseRequestModal,
  [MODAL_TYPES.ADMIN_VIEW_USER_MODAL]: AdminViewUserModal,
  [MODAL_TYPES.ADMIN_VIEW_DIVISION_MODAL]: AdminViewDivisionModal,
  [MODAL_TYPES.ADMIN_VIEW_INSTITUTION_MODAL]: AdminViewInstitutionModal,
  [MODAL_TYPES.ADMIN_REQUEST_MODAL]: AdminRequestModal,
  [MODAL_TYPES.ADMIN_ANNUAL_REPORT_MODAL]: AdminAnnualReportModal,
  [MODAL_TYPES.DATA_CHALLENGE_SELECTION_MODAL]: DataChallengeSelectionModal,
  [MODAL_TYPES.CHALLENGE_STUDY_PROMPT_MODAL]: ChallengeStudyPromptModal,
};

// handles the rendering of all the modals via redux
const ModalContainer = (props) => {
  const {
    isOpen,
    modalType,
    modalProps,
    openModal,
    closeModal,
    submitFormSync,
  } = props;

  if (!modalType) {
    return null;
  }
  const Modal = modalTypesMap[modalType];
  return (
    <Modal
      {...modalProps}
      isOpen={isOpen}
      openModal={openModal}
      closeModal={closeModal}
      submitFormSync={submitFormSync}
    />
  );
};

ModalContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  modalType: PropTypes.string,
  modalProps: PropTypes.object,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  submitFormSync: PropTypes.func,
};

export default ModalContainer;
