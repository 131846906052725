import _ from 'lodash';
import { getFormValues, submit } from 'redux-form';
import {
  ANNUAL_REPORT_RESET_STATE,
  ANNUAL_REPORT_SET_STATUS,
  ANNUAL_REPORT_SET_NEXT_STEP,
} from '../../common/types';


// default header for any api calls to the backend
const getJWT = () => ({
  headers: {
    Authorization: `Bearer ${localStorage.getItem('JWT')}`,
  },
});

const annualReportSteps = id => [
  {
    stepName: 'Annual Report',
    formName: 'annualReport',
    to: `/annualreport/${id}/annualReport`,
  },
  {
    stepName: 'Review and Submit Annual Report',
    formName: 'review-and-submit',
    to: `/annualreport/${id}/review-and-submit`,
  },
];

const stepToIndexMap = {
  'Annual Report': 0,
  'Annual Report Submit': 1,
  'Admin Approval': 2,
};

export const getAnnualReportSteps = id => annualReportSteps(id);

export const createAnnualReport = requestId => async () => {
  try {
    const url = '/workbench-api/requests/create-annual-report';
    const init = {
      method: 'POST',
      body: JSON.stringify({
        requestId,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem('JWT')}`,
        'Content-Type': 'application/json',
      },
    };
    const response = await fetch(url, init);
    const data = await response.json();
    if (response.ok) {
      return data;
    }
    return null;
  } catch (error) {
    throw error;
  }
};


export const setAnnualReportStatus = (annualReport, requestId, pathName, uploadData) => {
  const steps = getAnnualReportSteps(requestId);
  const nextStepToCompleteIndex = stepToIndexMap[annualReport.annualReportWorkflow.nextStepToComplete];
  let currStepIndex = _.findIndex(steps, step => step.to === pathName);
  if (currStepIndex < 0 || currStepIndex > nextStepToCompleteIndex) {
    currStepIndex = nextStepToCompleteIndex;
  }
  return {
    type: ANNUAL_REPORT_SET_STATUS,
    payload: {
      data: annualReport,
      status: annualReport.annualReportWorkflow.status,
      authorizedRepresentative: annualReport.authorizedRepresentative,
      steps,
      currStepIndex,
      nextStepToCompleteIndex,
      uploadData,
      reportYear: annualReport.reportYear,
    },
  };
};


// Gets the request status data, this includes the study name, request type, nextStepToComplete, etc.
// This is first api call made when user goes to /request/{id}. If the repsonse is successfull
// then dispatch the request status data, otherwise dispatch error to be displayed on the RequestContainer
export const getAnnualReportStatus = (annualReportId, pathname) => {
  const url = `/workbench-api/requests/${annualReportId}/annual-report`;

  return async (dispatch) => {
    let data;
    const response = await fetch(url, getJWT());

    if (response.ok) {
      data = await response.json();
    }
    dispatch(setAnnualReportStatus(data, annualReportId, pathname));
  };
};

export const resetState = () => ({
  type: ANNUAL_REPORT_RESET_STATE,
});


// reformat the input data for the backend
const reformatFormData = (id, step, data, requestId, source) => {
  const outcomeTypes = ['publications', 'presentations', 'patents', 'otheroutcomes', 'significantfindings'];
  const fieldTypes = { publications: 'Publication', presentations: 'Presentation', patents: 'Patent', otheroutcomes: 'Other', significantfindings: 'Significant Finding' };
  const hasOutcomeMap = { otheroutcomes: 'Other', patents: 'Patents', presentations: 'Presentations', publications: 'Publications', significantfindings: 'SignifcantFindings' };
  const formData = {};
  if (step === 'annualReport') {
    outcomeTypes.forEach((outcomeType, index) => {
      formData[`has${hasOutcomeMap[outcomeType]}`] = data[`has${outcomeType}`];
      if (data[`has${outcomeType}`] === 'true') {
        let adjustedName = outcomeType;
        if (outcomeType === 'otheroutcomes') adjustedName = 'other';
        if (outcomeType === 'significantfindings') adjustedName = 'significantFindings';
        formData[adjustedName] = [];
        data[outcomeType].map((outcome) => {
          let date = null;
          if (outcome.date) {
            date = new Date(outcome.date).toISOString();
          }
          // Removing all blank fields
          const keys = Object.keys(outcome);
          const formattedOutcome = {};
          keys.forEach((key) => {
            if (outcome[key] !== '') {
              formattedOutcome[key] = outcome[key];
            }
          });
          // adding reformated fields to formData
          const individualOutcome = {
            ...formattedOutcome,
            type: fieldTypes[outcomeType],
            date,
            requestIds: [requestId],
            source: `Annual Report ${source}`,
            sourceOther: null,
          };
          formData[adjustedName].push(individualOutcome);
        });
      }
    });
    if (data.additionalInformation) formData.additionalInformation = data.additionalInformation;
  } else {
    formData.fullName = data?.name;
  }
  return formData;
};

export const saveAnnualReportStep = (id, step, data, requestId, source) => {
  let form = step;
  if (step === 'annualReport') {
    form = 'annual-report';
  } else {
    form = 'annual-report-submit';
  }
  const url = `/workbench-api/requests/${id}/${form}`;
  const body = reformatFormData(id, step, data, requestId, source);
  const init = {
    body: JSON.stringify(body),
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('JWT')}`,
      'Content-Type': 'application/json',
    },
  };

  return async (dispatch, getState) => {
    const response = await fetch(url, init);
    const json = await response.json();
    if (response.ok) {
      const nextStepToComplete = json.annualReportWorkflow.nextStepToComplete;
      const nextStepToCompleteIndex = stepToIndexMap[nextStepToComplete];
      dispatch({
        type: ANNUAL_REPORT_SET_NEXT_STEP,
        payload: nextStepToCompleteIndex,
      });
    } else {
      throw json;
    }
    return json;
  };
};


export const submitFormAsync = (id, form, displayError, requestId, source) => (dispatch, getState) => new Promise((resolve, reject) => {
  const values = getFormValues(form)(getState());

  // POST call to save the user's data, if we get 200 then resolve the promise, otherwise reject
  // the promise and display any validation errors if needed
  return saveAnnualReportStep(
    id,
    form,
    values,
    requestId,
    source,
  )(dispatch, getState)
    .then(() => {
      resolve();
    })
    .catch(() => {
      if (displayError) {
        // this is the second call to the POST in order to display the errors in the page
        dispatch(submit(form));
      }
      reject();
    });
});
