import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LoaderContainer from './LoaderContainer';

// Full page loader which activates when 'loading' in LoaderReducer is true
export const FullPageLoader = props => (
  <LoaderContainer
    active={props.loading}
    text="Loading..."
    spinner
    background="rgba(0,0,0,0.5)"
    spinnerSize="100px"
    fullPage
  />
);

FullPageLoader.propTypes = {
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ loader }) => ({
  loading: loader.loading,
});

export default connect(mapStateToProps)(FullPageLoader);
