import React, { useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import {
  DatePicker,
  InputBox,
  SearchSelect,
  Textarea,
} from '../../../../common/inputs';
import { ClearButton } from '../../../../common/buttons';
import { ReactComponent as TrashIcon } from '../../../../img/icons/TrashIcon.svg';

// only allow user to enter one character for middle initial
export const parseMiddleInitial = (value) => {
  if (value.match(/^[a-zA-Z]{0,1}$/)) {
    return value;
  }
  return undefined;
};

// renders the input fields (first name, last name, etc) for one researcher
const OtherOutcomesForm = (props) => {
  const {
    member,
    index,
    fields,
    type,
    label,
    minDate,
    maxDate,
  } = props;

  const [selectedDate, setSelectedDate] = useState(maxDate);

  const renderRemoveBtn = () => {
    if (fields.length > 1) {
      return (
        <ClearButton
          type="button"
          onClick={() => fields.remove(index)}
          onKeyPress={() => fields.remove(index)}
          label={`Remove ${label}`}
          align="right"
          icon={<TrashIcon />}
        />
      );
    }
    return null;
  };
  let sectionHeader;
  if (type === 'significantfinding') {
    sectionHeader = 'SIGNIFIGANT FINDINGS';
  } else if (type === 'otheroutcome') {
    sectionHeader = 'OTHER OUTCOMES';
  } else {
    sectionHeader = `${_.toUpper(type)}s`;
  }

  return (
    <div>
      <div className="row">
        <h2 className="form-heading-1"> {sectionHeader} OUTCOME INFORMATION {index + 1}</h2>
        <div className="col align-self-end">
          {renderRemoveBtn()}
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col">
          <Field
            component={InputBox}
            id="outcome-title"
            name={`${member}.title`}
            label="Outcome Title"
            placeholder="Please enter the title of the outcome (300 characters including spaces)"
            required
          />
        </div>
      </div>
      <div className="form-group">
        <div className="row">
          <div className="col">
            <Field
              id="outcome-date"
              name={`${member}.date`}
              label="Outcome Date (if date is unknown please select the 1st of the month)"
              ariaLabel="Please enter the outcome date in the format mm/dd/yyyy. If date is unknown please select the 1st of the month"
              form="annualReport"
              required
              // below props are used by ReactDatePicker
              selected={selectedDate}
              maxDate={maxDate}
              minDate={minDate}
              component={DatePicker}
              placeholderText="Select outcome date"
              dateFormat="MMMM d, yyyy"
              onChange={date => setSelectedDate(date)}
              className="form-control"
              popperClassName="mt_-20"
              errorPopperClassName="mt-25"
              showYearDropdown
              showMonthDropdown
              dropdownMode="select"
            />
          </div>
          <div className="col">
            <Field
              component={Textarea}
              id="outcome-description"
              name={`${member}.description`}
              label="Description"
              placeholder="Please enter outcome description (700 characters including spaces)"
              required
            />
          </div>
        </div>
      </div>
    </div>
  );
};

OtherOutcomesForm.propTypes = {
  member: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  fields: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  minDate: PropTypes.instanceOf(Date).isRequired,
  maxDate: PropTypes.instanceOf(Date).isRequired,
};

export default OtherOutcomesForm;
