import React, { useState } from 'react';
import { camelCase } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ADMIN_REQUEST_ACTION_TYPES, ALERT_TYPES } from '../../types';
import AdminRequestView from '../../../features/admin/request/components/AdminRequestView';
import { ApproveRequestForm, DenyRequestForm, RemoveRequestForm, UploadExecutedUseAgreementForm, UploadFulfillmentForm, TerminateRequestForm, UploadExecutedRenewalForm, UploadExecutedAORModificationForm } from '../../../features/admin/request/components/forms';
import { changeRequest } from '../../../features/admin/AdminActions';
import Alert from '../../alert';
import { getSuccessfulAlertMessage, parseBackendErrors } from '../../../features/admin/request/components/forms/AdminRequestValidator';
import { scrollToTopOfDiv } from '../../utils/utils';

const AdminRequestModal = (props) => {
  const {
    isOpen,
    closeModal,
    request,
    requestId,
    type,
    submitFormSync,
    setLoading,
    requestType,
    reasons,
  } = props;
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  let title;
  let Form;
  let formType;
  let id;

  const handleSubmit = (values) => {
    let changeType = camelCase(type);
    if (type === ADMIN_REQUEST_ACTION_TYPES.UPLOAD) {
      changeType = 'upload-executed-use-agreement';
    } else if (type === ADMIN_REQUEST_ACTION_TYPES.SHIP) {
      changeType = 'upload-shipment-fulfillment-form';
    } else if (type === ADMIN_REQUEST_ACTION_TYPES.UPLOAD_RENEWAL_FORM) {
      changeType = 'upload-executed-renewal-form';
    } else if (type === ADMIN_REQUEST_ACTION_TYPES.UPLOAD_AOR_MODIFICATION_FORM) {
      changeType = 'upload-executed-aor-modification-form';
    }
    setLoading(true);

    if (type === ADMIN_REQUEST_ACTION_TYPES.UPLOAD_AOR_MODIFICATION_FORM || type === ADMIN_REQUEST_ACTION_TYPES.UPLOAD_RENEWAL_FORM) {
      id = requestId;
    } else if (props.request.modificationId) {
      id = props.request.modificationId;
    } else {
      id = props.request.id;
    }

    props.changeRequest(id, values, changeType, request.type)
      .then(() => {
        setAlertType(ALERT_TYPES.SUCCESS);
        setAlertMessage(getSuccessfulAlertMessage(type));
        scrollToTopOfDiv('modal-header');
        setLoading(false);
      })
      .catch((resp) => {
        setAlertType(ALERT_TYPES.ERROR);
        setAlertMessage(parseBackendErrors(resp));
        scrollToTopOfDiv('modal-header');
        setLoading(false);
      });
  };

  switch (type) {
    case ADMIN_REQUEST_ACTION_TYPES.VIEW:
      title = 'View Request Summary';
      break;
    case ADMIN_REQUEST_ACTION_TYPES.APPROVE:
      title = 'Approve Request';
      Form = ApproveRequestForm;
      formType = 'approveRequestForm';
      break;
    case ADMIN_REQUEST_ACTION_TYPES.DENY:
      title = 'Deny Request';
      Form = DenyRequestForm;
      formType = 'denyRequestForm';
      break;
    case ADMIN_REQUEST_ACTION_TYPES.REMOVE:
      title = 'Remove Request';
      Form = RemoveRequestForm;
      formType = 'removeRequestForm';
      break;
    case ADMIN_REQUEST_ACTION_TYPES.UPLOAD:
      title = (requestType === 'biospecimen') ? 'Upload Executed Material Transfer Agreement'
        : 'Upload Executed Use Agreement';
      Form = UploadExecutedUseAgreementForm;
      formType = 'uploadRequestFileForm';
      break;
    case ADMIN_REQUEST_ACTION_TYPES.SHIP:
      title = 'Upload Shipment Fulfillment Form';
      Form = UploadFulfillmentForm;
      formType = 'uploadFulfillmentForm';
      break;
    case ADMIN_REQUEST_ACTION_TYPES.TERMINATE:
      title = 'Terminate Request';
      Form = TerminateRequestForm;
      formType = 'terminateRequestForm';
      break;
    case ADMIN_REQUEST_ACTION_TYPES.RENEWAL_APPROVE:
      title = 'Approve Request Renewal';
      Form = ApproveRequestForm;
      formType = 'approveRequestForm';
      break;
    case ADMIN_REQUEST_ACTION_TYPES.UPLOAD_RENEWAL_FORM:
      title = 'Upload Executed Renewal Form';
      Form = UploadExecutedRenewalForm;
      formType = 'uploadExecutedRenewalForm';
      break;
    case ADMIN_REQUEST_ACTION_TYPES.RENEWAL_DENY:
      title = 'Deny Request Renewal';
      Form = DenyRequestForm;
      formType = 'denyRequestForm';
      break;
    case ADMIN_REQUEST_ACTION_TYPES.AOR_MODIFICATION_APPROVE:
      title = 'Approve AOR Modification';
      Form = ApproveRequestForm;
      formType = 'approveRequestForm';
      break;
    case ADMIN_REQUEST_ACTION_TYPES.UPLOAD_AOR_MODIFICATION_FORM:
      title = 'Upload Executed AOR Modification Form';
      Form = UploadExecutedAORModificationForm;
      formType = 'uploadExecutedAORModificationForm';
      break;
    case ADMIN_REQUEST_ACTION_TYPES.AOR_MODIFICATION_DENY:
      title = 'Deny AOR Modification';
      Form = DenyRequestForm;
      formType = 'denyRequestForm';
      break;
    default:
      break;
  }

  const requestUsers = [{ ...request.requestor, userType: 'Requestor' }, { ...request.principalInvestigator, userType: 'Principal Investigator' }];
  const recipients = {};
  requestUsers.forEach(user => (recipients[`${user.id}`] = true));
  const initialValues = (formType === 'terminateRequestForm') ? { recipients } : null;

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={closeModal}
        size="lg"
        backdrop="static"
      >
        <ModalHeader id="modal-header" toggle={closeModal} tag="h2">
          {title}
        </ModalHeader>
        <ModalBody>
          <Alert
            alertType={alertType}
            message={alertMessage}
            allowClose={false}
          />
          <AdminRequestView
            request={request}
            isRenewal={props.isRenewal}
            isAORModification={props.isAORModification}
          />
          {Form && (
            <Form
              onSubmit={handleSubmit}
              irbRequired={request.irbRequired}
              requestType={request.type}
              reasons={reasons}
              requestUsers={requestUsers}
              initialValues={initialValues}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <button
            type="submit"
            className="btn modal-btn modal-btn-blue"
            onClick={closeModal}
          >
            Close
          </button>
          { // hide submit button after successful submission
            type !== ADMIN_REQUEST_ACTION_TYPES.VIEW && alertType !== ALERT_TYPES.SUCCESS && (
              <button
                type="submit"
                className="btn modal-btn modal-btn-blue"
                onClick={() => submitFormSync(formType)}
              >
                Submit
              </button>
            )}
        </ModalFooter>
      </Modal>
    </div>
  );
};

AdminRequestModal.propTypes = {
  request: PropTypes.object,
  requestId: PropTypes.number,
  type: PropTypes.string,
  closeModal: PropTypes.func,
  isOpen: PropTypes.bool,
  submitFormSync: PropTypes.func,
  setLoading: PropTypes.func,
  changeRequest: PropTypes.func,
  requestType: PropTypes.string,
  reasons: PropTypes.array,
  isRenewal: PropTypes.bool,
  isAORModification: PropTypes.bool,
  aormodificationId: PropTypes.number,
  modificationId: PropTypes.number,
};

const actions = { changeRequest };
export default connect(null, actions)(AdminRequestModal);
