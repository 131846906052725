import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { InputBox, DropdownSelect } from '../../../inputs';

// only allow user to enter one character for middle initial
export const parseMiddleInitial = (value) => {
  if (value.match(/^[a-zA-Z]{0,1}$/)) {
    return value;
  }
  return undefined;
};

// only allow user to enter numerical values for phone number
export const parsePhoneNumber = (value) => {
  if (value.match(/^\d*$/)) {
    return value;
  }
  return undefined;
};

const UserForm = (props) => {
  const {
    titles,
    disableEmailAddress,
    disableConfirmEmail,
    disableTitle,
    disableFirstName,
    disableLastName,
    disableMiddleInitial,
    disableOccupation,
    disablePhone,
    emailAddressTooltip,
    emailOnChange,
    emailPlaceholder,
    displayConfirmEmail,
    displayPassword,
    ariaLabel,
    section
  } = props;

  const titleOptions = titles && titles.map(title => ({ value: title, item: title }));

  return (
    <>
      <div className="row">
        <div className={displayConfirmEmail ? 'col-12 col-md-6' : 'col-12'}>
          <Field
            component={InputBox}
            id="email"
            name="emailAddress"
            type="email"
            label="Email Address"
            placeholder={emailPlaceholder || 'Please enter Email Address'}
            disabled={disableEmailAddress}
            onChange={emailOnChange}
            ariaLabel={ariaLabel}
            tooltip={emailAddressTooltip && { id: 'emailAddressTooltip', message: emailAddressTooltip }}
            required
          />
        </div>
        {displayConfirmEmail && (
          <div className="col-12 col-md-6">
            <Field
              component={InputBox}
              id="emailConfirm"
              name="emailConfirm"
              label="Email Confirm"
              placeholder="Confirm Email Address"
              disabled={disableConfirmEmail}
              required
            />
          </div>
        )}
        { displayPassword && (
          <>
            <div className="col-12 col-md-6">
              <Field
                component={InputBox}
                type="password"
                id="password"
                name="password"
                label="Password"
                placeholder="Password"
                required
              />
            </div>
            <div className="col-12 col-md-6">
              <Field
                component={InputBox}
                type="password"
                id="passwordConfirm"
                name="passwordConfirm"
                label="Confirm Password"
                placeholder="Confirm Password"
                required
              />
            </div>
          </>
        )}
        <div className="col-12 col-sm-12 col-md">
          <Field
            component={DropdownSelect}
            id="title"
            label="Title"
            placeholder="Title"
            ariaLabel={`${section ? section : ''} Title`}
            name="title"
            options={titleOptions}
            disabled={disableTitle}
          />
        </div>
        <div className="col-12 col-sm-12 col-md-4">
          <Field
            component={InputBox}
            id="firstName"
            name="firstName"
            label="First Name"
            ariaLabel={`${section ? section : ''}  First Name`}
            placeholder="First Name"
            disabled={disableFirstName}
            required
          />
        </div>
        <div className="col-12 col-sm-12 col-md-4">
          <Field
            component={InputBox}
            id="lastName"
            name="lastName"
            label="Last Name"
            ariaLabel={`${section ? section : ''} Last Name`}
            placeholder="Last Name"
            disabled={disableLastName}
            required
          />
        </div>
        <div className="col-12 col-sm-12 col-md">
          <Field
            component={InputBox}
            id="middleInitial"
            name="middleInitial"
            label="M.I."
            ariaLabel={`${section ? section : ''} Middle Initial`}
            placeholder="M.I."
            parse={parseMiddleInitial}
            disabled={disableMiddleInitial}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-sm-12 col-md">
          <Field
            component={InputBox}
            id="occupation"
            name="occupation"
            label="Job Title/Position"
            ariaLabel={`${section ? section : ''} Job Title/Position`}
            placeholder="Job Title/Position"
            disabled={disableOccupation}
            required
          />
        </div>
        <div className="col-12 col-sm-12 col-md">
          <Field
            component={InputBox}
            id="phone"
            type="tel"
            name="phone"
            label="Phone Number"
            ariaLabel={`${section ? section : ''}  Phone Number`}
            placeholder="Please enter Phone Number"
            parse={parsePhoneNumber}
            disabled={disablePhone}
          />
        </div>
      </div>
    </>
  );
};

UserForm.propTypes = {
  titles: PropTypes.arrayOf(PropTypes.string),
  disableEmailAddress: PropTypes.bool,
  disableConfirmEmail: PropTypes.bool,
  disableTitle: PropTypes.bool,
  disableFirstName: PropTypes.bool,
  disableLastName: PropTypes.bool,
  disableMiddleInitial: PropTypes.bool,
  disableOccupation: PropTypes.bool,
  disablePhone: PropTypes.bool,
  emailAddressTooltip: PropTypes.string,
  emailOnChange: PropTypes.func,
  emailPlaceholder: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  displayConfirmEmail: PropTypes.bool,
  displayPassword: PropTypes.bool,
};

export { UserForm };
export default UserForm;
