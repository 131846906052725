import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { MODAL_TYPES, REQUEST_TYPES } from '../../types';
import DataRequestGuidelines from '../components/DataRequestGuidelines';
import BiospecimenRequestGuidelines from '../components/BiospecimenRequestGuidelines';

// modal that renders on the cart page when user initiates a request process
const RequestGuidelinesModal = (props) => {
  const {
    isOpen,
    openModal,
    closeModal,
    getPreviousRequests,
    createRequest,
    history,
    studyId,
    requestType,
    hasUnresolvedConstraints,
    ...rest // remaining props are for the ReuseRequestModal
  } = props;

  const modalHeader = requestType === REQUEST_TYPES.DATA ? 'Data' : 'Biospecimen';

  const modalProps = {
    createRequest,
    history,
    studyId,
    requestType,
    ...rest,
  };

  // If the user does not have any previous requests, then create the request for
  // the given studyId. Otherwise open another modal to give the user the option
  // to reuse data from a previous request
  const handleNextStep = (previousRequests) => {
    closeModal();
    if (_.isEmpty(previousRequests)) {
      createRequest(studyId)
        .then((resp) => {
          history.push(`/request/${resp.id}`);
        });
    } else {
      const reuseRequestModalProps = {
        ...modalProps,
        previousRequests,
      };
      openModal(MODAL_TYPES.REUSE_REQUEST_MODAL, reuseRequestModalProps);
    }
  };

  const RequestGuidelines = requestType === REQUEST_TYPES.DATA ? DataRequestGuidelines : BiospecimenRequestGuidelines;

  let checkConstraints;

  props.getUnresolvedConstraints(props.studyId)
    .then((constraints) => {
      if (constraints.length !== 0) checkConstraints = [{ ...constraints[0], type: 'bio' }];
    });

  props.getUnresolvedDataConstraints(props.studyId)
    .then((constraints) => {
      if (constraints.distributionLimitationsOther !== null) {
        checkConstraints = [{ id: constraints.id, items: [], restriction: constraints.distributionLimitationsOther, type: 'data' }];
      }
    });

  return (
    <Modal
      isOpen={isOpen}
      toggle={closeModal}
      size="lg"
      backdrop="static"
      labelledBy={`${modalHeader}-request-guidelines`}
    >
      <ModalHeader toggle={closeModal} tag="h2" id={`${modalHeader}-request-guidelines`}>
        {`${modalHeader} Request Guidelines`}
      </ModalHeader>
      <ModalBody>
        <RequestGuidelines />
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          aria-label="Cancel data request."
          className="btn modal-btn btn-secondary"
          onClick={closeModal}
        >
          Cancel
        </button>
        <button
          type="button"
          aria-label="Continue to data request form."
          className="btn modal-btn modal-btn-blue"
          onClick={() => {
            if (checkConstraints) {
              closeModal();
              const constraintsModalProps = {
                ...modalProps,
                getPreviousRequests,
                checkConstraints,
              };
              openModal(MODAL_TYPES.CONSTRAINTS_MODAL, constraintsModalProps);
            } else {
              getPreviousRequests()
                .then(data => handleNextStep(data));
            }
          }}
        >
          Continue
        </button>
      </ModalFooter>
    </Modal>
  );
};

RequestGuidelinesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  getPreviousRequests: PropTypes.func.isRequired,
  createRequest: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  studyId: PropTypes.number.isRequired,
  requestType: PropTypes.string.isRequired,
  hasUnresolvedConstraints: PropTypes.bool,
  getUnresolvedConstraints: PropTypes.func.isRequired,
  getUnresolvedDataConstraints: PropTypes.func.isRequired,
};

export default RequestGuidelinesModal;
