import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import '../Button/style.scss';
import { ReactComponent as LeftArrowIcon } from '../../../img/icons/arrows/Left.svg';
import { ReactComponent as RightArrowIcon } from '../../../img/icons/arrows/Right.svg';

const FormNavButton = (props) => {
  const classes = `btn btn-form-nav ${props.classes}`;
  let icon;
  switch (props.label.toUpperCase()) {
    case 'PREVIOUS':
      icon = <span className="left-arrow-icon"><LeftArrowIcon height="13px" /></span>;
      break;
    case 'NEXT':
      icon = <span className="right-arrow-icon"><RightArrowIcon height="13px" /></span>;
      break;
    default:
      icon = null;
  }

  return (
    <button
      className={classes}
      type={props.type}
      onClick={props.onClick}
      onKeyPress={props.onKeyPress}
      disabled={props.disabled}
    >
      <span>
        {icon}
        {props.label.toUpperCase()}
      </span>
    </button>
  );
};

FormNavButton.propTypes = {
  label: PropTypes.string.isRequired,
  classes: PropTypes.string,
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
  type: PropTypes.string,
  disabled: PropTypes.bool,
};

FormNavButton.defaultTypes = {
  classes: '',
  role: 'button',
};

export default FormNavButton;
