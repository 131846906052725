import { HOME_RECENT_STUDIES, HOME_SET_ANNOUNCEMENTS } from '../../common/types';

const INITIAL_STATE = {
  recentStudies: [],
  announcements: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HOME_RECENT_STUDIES:
      return { ...state, recentStudies: action.payload };
    case HOME_SET_ANNOUNCEMENTS:
      return {
        ...state,
        announcements: action.payload,
      };
    default:
      return state;
  }
};
