import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';
import AuthReducer from '../../features/auth/authReducer';
import RequestReducer from '../../features/request/RequestReducer';
import RenewalReducer from '../../features/renewal/RenewalReducer';
import ModifyAORReducer from '../../features/modifyAOR/ModifyAORReducer';
import HomeReducer from '../../features/home/HomeReducer';
import FooterReducer from '../../common/footer/FooterReducer';
import CartReducer from '../../features/cart/CartReducer';
import CuratorReducer from '../../features/curator/CuratorReducer';
import StudyOverviewReducer from '../../features/studyOverview/studyOverviewReducer';
import HeaderReducer from '../../common/header/HeaderReducer';
import LoaderReducer from '../../common/loader/LoaderReducer';
import ModalReducer from '../../common/modal/ModalReducer';
import ProfileReducer from '../../features/profile/ProfileReducer';
import WorkspaceReducer from '../../features/workspace/WorkspaceReducer';
import SubmissionReducer from '../../features/submission/SubmissionReducer';
import RegistrationReducer from '../../features/register/RegistrationReducer';
import FeedbackReducer from '../../features/feedback/FeedbackReducer';
import AdminReducer from '../../features/admin/AdminReducer';
import SearchReducer from '../../features/search/SearchReducer';
import ReportReducer from '../../features/reports/ReportReducer';
import DatasetOverviewReducer from '../../features/datasetOverview/DatasetOverviewReducer';
import TableReducer from '../../features/resources/publications/TableReducer';
import AnnualReportReducer from '../../features/annualReport/AnnualReportReducer';

export default history => combineReducers({
  router: connectRouter(history),
  form: formReducer,
  annualReport: AnnualReportReducer,
  auth: AuthReducer,
  header: HeaderReducer,
  footer: FooterReducer,
  home: HomeReducer,
  studyOverview: StudyOverviewReducer,
  curator: CuratorReducer,
  cart: CartReducer,
  request: RequestReducer,
  renewal: RenewalReducer,
  modifyAOR: ModifyAORReducer,
  search: SearchReducer,
  submission: SubmissionReducer,
  loader: LoaderReducer,
  modal: ModalReducer,
  profile: ProfileReducer,
  workspace: WorkspaceReducer,
  registration: RegistrationReducer,
  feedback: FeedbackReducer,
  reports: ReportReducer,
  admin: AdminReducer,
  datasetOverview: DatasetOverviewReducer,
  table: TableReducer,
});
