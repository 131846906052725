import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

// This is the only modal that isn't opened via the ModalContainer. Instead this modal is
// rendered directly in the TimeoutWarningContainer because I didn't code that shit and have
// no idea how it works so I don't want to break anything (though imo the TimeoutWarningContainer
// needs to be redone, I don't think it's properly tracking how long the user has been idle :/)
const TimeoutWarningModal = ({ isOpen, toggle, resetTimers }) => (
  <Modal
    isOpen={isOpen}
    toggle={toggle}
    backdrop="static"
  >
    <ModalHeader tag="h2">
      Inactivity Timeout Warning
    </ModalHeader>
    <ModalBody>
      <h2>You will be logged out in 15 minutes due to inactivity. Click the button below to remain active.</h2>
    </ModalBody>
    <ModalFooter>
      <button
        type="button"
        className="btn modal-btn modal-btn-blue"
        onClick={resetTimers}
        tabIndex={0}
      >
        Stay Active
      </button>
    </ModalFooter>
  </Modal>
);

TimeoutWarningModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  resetTimers: PropTypes.func.isRequired,
};

export default TimeoutWarningModal;
