import {
  FEEDBACK_SET_FORM_SUPPORT,
  FEEDBACK_RESET,
} from '../../common/types';

export const INITIAL_STATE = {
  feedBackTypes: [],
  feedBackSections: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FEEDBACK_SET_FORM_SUPPORT:
      return {
        ...state,
        [action.payload.formSupportType]: action.payload.data,
      };
    case FEEDBACK_RESET:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};
