import React from 'react';
import PropTypes from 'prop-types';
import AddressPrettyPrint from '../address/AddressPrettyPrint';
import './style.scss';

const UserPrettyPrint = ({ user }) => {
  if (!user) return null;
  const {
    emailAddress,
    title,
    firstName,
    middleInitial,
    lastName,
    occupation,
    phone,
    institutionDivision: {
      name: institutionDivisionName,
      address: institutionDivisionAddress,
      institution,
    },
  } = user;

  const getFullName = () => {
    let fullName = '';
    fullName = title ? `${title} ${firstName}` : firstName;
    fullName = middleInitial ? `${fullName} ${middleInitial} ${lastName}` : `${fullName} ${lastName}`;
    return fullName;
  };

  const institutionType = institution.isForProfit ? 'For profit' : 'Not for profit';

  return (
    <div className="row pretty-print justify-content-between">
      <div className="col-md-6">
        <div className="row">
          <div className="col-xs-12 col-sm-6 py-2"><b>Email Address</b></div>
          <div className="col-xs-12 col-sm-6 py-2">{emailAddress}</div>

          <div className="col-xs-12 col-sm-6 py-2"><b>Name</b></div>
          <div className="col-xs-12 col-sm-6 py-2">{getFullName()}</div>

          <div className="col-xs-12 col-sm-6 py-2"><b>Job Title/Position</b></div>
          <div className="col-xs-12 col-sm-6 py-2">{occupation}</div>

          <div className="col-xs-12 col-sm-6 py-2"><b>Institution</b></div>
          <div className="col-xs-12 col-sm-6 py-2">{institution.name}</div>

          <div className="col-xs-12 col-sm-6 py-2"><b>Institution Type</b></div>
          <div className="col-xs-12 col-sm-6 py-2">{institutionType}</div>

          <div className="col-xs-12 col-sm-6 py-2"><b>Phone</b></div>
          <div className="col-xs-12 col-sm-6 py-2">{phone || 'N/A'}</div>

          <div className="col-xs-12 col-sm-6 py-2"><b>Institution Address</b></div>
          <div className="col-xs-12 col-sm-6 py-2"><AddressPrettyPrint address={institution.address} /></div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="row">
          <div className="col-xs-12 col-sm-6 py-2"><b>School&#8203;/Division&#8203;/Center</b></div>
          <div className="col-xs-12 col-sm-6 py-2">{institutionDivisionName}</div>

          <div className="col-xs-12 col-sm-6 py-2"><b>Division Address</b></div>
          <div className="col-xs-12 col-sm-6 py-2"><AddressPrettyPrint address={institutionDivisionAddress} /></div>
        </div>
      </div>
    </div>
  );
};

UserPrettyPrint.propTypes = {
  user: PropTypes.shape({
    emailAddress: PropTypes.string,
    firstName: PropTypes.string,
    id: PropTypes.number,
    institutionDivision: PropTypes.shape({
      name: PropTypes.string,
      address: PropTypes.shape({
        address1: PropTypes.string,
        address2: PropTypes.string,
        city: PropTypes.string,
        country: PropTypes.string,
        state: PropTypes.string,
        zip: PropTypes.string,
      }),
      id: PropTypes.number,
      institution: PropTypes.shape({
        address: PropTypes.shape({
          address1: PropTypes.string,
          address2: PropTypes.string,
          city: PropTypes.string,
          country: PropTypes.string,
          state: PropTypes.string,
          zip: PropTypes.string,
        }),
        isForProfit: PropTypes.bool,
        name: PropTypes.string,
      }),
    }),
    institutionDivisionId: PropTypes.number,
    lastName: PropTypes.string,
    locked: PropTypes.bool,
    middleInitial: PropTypes.string,
    occupation: PropTypes.string,
    phone: PropTypes.string,
    title: PropTypes.string,
  }),
};

export default UserPrettyPrint;
