import {
  GET_ITEMS_IN_CART,
  UPDATE_ITEMS_IN_CART,
} from '../types';

export const INITIAL_STATE = {
  numCartItems: -1,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ITEMS_IN_CART:
      return {
        numCartItems: action.payload.numCartItems,
      };
    case UPDATE_ITEMS_IN_CART:
      return {
        numCartItems: action.payload + state.numCartItems,
      };
    default:
      return state;
  }
};
