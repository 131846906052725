import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, formValueSelector } from 'redux-form';
import { Checkbox, InputBox, DropdownSelect, RadioButton } from '../../../inputs';
import FieldErrorLabel from '../../components/FieldErrorLabel';
import './style.scss';

// only allow user to enter numerical values for zip code
const parseNumber = (value) => {
  if (value.match(/^\d*$/)) {
    return value;
  }
  return undefined;
};

// renders a dialog to allow the user to create a new institution or division
const CustomInstitutionDivision = (props) => {
  const {
    fieldName,
    fieldLabel,
    change,
    selectedInstitution,
    country,
    countries,
    states,
    institutionTypes,
  } = props;

  // change the state and zipcode fields if the user selects a US or non-US country
  let StateComponent = InputBox;
  let stateLabel = 'Province/Region';
  let zipLabel = 'Postal Code';
  if (country === 'United States of America') {
    StateComponent = DropdownSelect;
    stateLabel = 'State';
    zipLabel = 'Zip Code';
  }

  // change the division name to the institution name if the user selects the 'Use My Institution Name' checkbox
  const handleUseInstNameOnChange = (e, newValue, prevValue) => {
    const useInstName = !prevValue;
    // if update profile -> selectedInstitution.institution.name, if registration -> selectedInstitution.name;
    const institutionName = selectedInstitution.institution ? selectedInstitution.institution.name : selectedInstitution.name;
    if (useInstName) {
      change(`${fieldName}.name`, institutionName);
    } else {
      change(`${fieldName}.name`, null);
    }
    change(`${fieldName}.useInstName`, useInstName);
  };

  // change the division address to the institution address if the user selects the 'Use My Institution Address' checkbox
  const handleUseInstAddressOnChange = (e, newValue, prevValue) => {
    const useInstAddress = !prevValue;
    if (useInstAddress && selectedInstitution.address) {
      change(`${fieldName}.address.country`, selectedInstitution.address.country);
      change(`${fieldName}.address.address1`, selectedInstitution.address.address1);
      change(`${fieldName}.address.address2`, selectedInstitution.address.address2);
      change(`${fieldName}.address.city`, selectedInstitution.address.city);
      change(`${fieldName}.address.state`, selectedInstitution.address.state);
      change(`${fieldName}.address.zip`, selectedInstitution.address.zip);
    } else {
      change(`${fieldName}.address.country`, null);
      change(`${fieldName}.address.address1`, null);
      change(`${fieldName}.address.address2`, null);
      change(`${fieldName}.address.city`, null);
      change(`${fieldName}.address.state`, null);
      change(`${fieldName}.address.zip`, null);
    }
    change(`${fieldName}.useInstAddress`, useInstAddress);
  };

  const [disableIsForProfit, setDisableIsForProfit] = useState(false);
  const handleIsForProfitOnChange = (e, newValue) => {
    let selectedType = institutionTypes.find(type => type.id === parseInt(newValue, 10)).isForProfit;
    if (selectedType != null) {
      selectedType = selectedType.toString();
      setDisableIsForProfit(true);
    } else {
      setDisableIsForProfit(false);
    }
    change('division.institution.isForProfit', selectedType);
  };

  return (
    <div className="col-12">
      <div className="w-100 p-30 bkg-f1f1f1">
        <div className="row">
          <div className="col-12">
            <h3 className="fw-700">Create New {fieldLabel}</h3>
          </div>
          {fieldName === 'division.institution' && (
            <>
              <div className="col-12">
                <Field
                  component={DropdownSelect}
                  id={`${fieldName}.institutionType.id`}
                  name={`${fieldName}.institutionType.id`}
                  label="Institution Type"
                  placeholder="Select Institution Type"
                  options={institutionTypes.map(({ id, name }) => ({ item: name, value: id }))}
                  onChange={handleIsForProfitOnChange}
                  required
                />
              </div>
          <div className="form-group">
            <div className="p-20 align-items-center justify-content-center" role="radiogroup" id='institutionStatus'>
              <div className="col">
                <br />
                <label for='institutionStatus'>Institution Status <b style={{color: '#ef0000'}}>*</b></label>
                <br />
                <Field
                  component={RadioButton}
                  label="For profit"
                  value="true"
                  type="radio"
                  ariaLabel="For profit"
                  name="division.institution.isForProfit"
                  labelClassName="pt-10 pb-10"
                  disabled={disableIsForProfit}
                />
              </div>
              <div className="col">
                <Field
                  component={RadioButton}
                  label="Not for profit"
                  value="false"
                  type="radio"
                  ariaLabel="Not for profit"
                  name="division.institution.isForProfit"
                  labelClassName="pt-10 pb-10"
                  disabled={disableIsForProfit}
                />
              </div>
            </div>
          </div>
              <div className="col-12 ta-center">
                <FieldErrorLabel
                  name="division.institution.isForProfit"
                />
              </div>
            </>
          )}
          {fieldName === 'division' && (
            <>
              <div className="col-12 col-md-6 p-15">
                <Field
                  component={Checkbox}
                  id={`${fieldName}.useInstName`}
                  name={`${fieldName}.useInstName`}
                  label="Use My Institution Name"
                  onChange={handleUseInstNameOnChange}
                />
              </div>
              <div className="col-12 col-md-6 p-15">
                <Field
                  component={Checkbox}
                  id={`${fieldName}.useInstAddress`}
                  name={`${fieldName}.useInstAddress`}
                  label="Use My Institution Address"
                  onChange={handleUseInstAddressOnChange}
                />
              </div>
            </>
          )}
          <div className="col-12 col-md-6">
            <Field
              component={InputBox}
              id={`${fieldName}.name`}
              name={`${fieldName}.name`}
              label={`${fieldLabel} Name`}
              placeholder={`${fieldLabel} Name`}
              required
            />
          </div>
          <div className="col-12 col-md-6">
            <Field
              component={DropdownSelect}
              id={`${fieldName}.address.country`}
              name={`${fieldName}.address.country`}
              label="Country"
              placeholder="Country"
              options={countries.map(c => ({ item: c, value: c }))}
              required
            />
          </div>
          <div className="col-12">
            <Field
              component={InputBox}
              id={`${fieldName}.address.address1`}
              name={`${fieldName}.address.address1`}
              label="Address"
              placeholder={`${fieldLabel} Address`}
              required
            />
          </div>
          <div className="col-12">
            <Field
              component={InputBox}
              id={`${fieldName}.address.address2`}
              name={`${fieldName}.address.address2`}
              label="Address 2"
              placeholder="Address 2"
            />
          </div>
          <div className="col-12 col-md-5">
            <Field
              component={InputBox}
              id={`${fieldName}.address.city`}
              name={`${fieldName}.address.city`}
              label="City"
              placeholder="City"
              required
            />
          </div>
          <div className="col-12 col-md-4">
            <Field
              component={StateComponent}
              id={`${fieldName}.address.state`}
              name={`${fieldName}.address.state`}
              label={stateLabel}
              placeholder={stateLabel}
              options={states.map(s => ({ item: s, value: s }))}
              required={country === 'United States of America'}
            />
          </div>
          <div className="col-12 col-md-3">
            <Field
              component={InputBox}
              id={`${fieldName}.address.zip`}
              name={`${fieldName}.address.zip`}
              label={zipLabel}
              placeholder={zipLabel}
              required={country === 'United States of America'}
              parse={parseNumber}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

CustomInstitutionDivision.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fieldLabel: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  countries: PropTypes.array.isRequired,
  states: PropTypes.array.isRequired,
  selectedInstitution: PropTypes.object,
  country: PropTypes.string,
  institutionTypes: PropTypes.array,
};

const mapStateToProps = (state, { formName, fieldName }) => {
  const selector = formValueSelector(formName);
  return {
    country: selector(state, `${fieldName}.address.country`),
  };
};

export default connect(mapStateToProps)(CustomInstitutionDivision);
