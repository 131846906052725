import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { Textarea } from '../../../../../common/inputs';
import { validateDenyRequest } from './AdminRequestValidator';

export const DenyRequestForm = props => (
  <form onSubmit={props.handleSubmit}>
    <div>
      Are you sure you want to deny this request? If so, please provide a detailed justification for return.
    </div>
    <div className="form-group p-15">
      <Field
        component={Textarea}
        id="denialReason"
        name="denialReason"
        placeholder="Please enter reason for request denial."
      />
    </div>
  </form>
);

DenyRequestForm.propTypes = {
  handleSubmit: PropTypes.func,
};

export default reduxForm({
  form: 'denyRequestForm',
  warn: validateDenyRequest,
})(DenyRequestForm);
