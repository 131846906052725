import React from 'react';
import PropTypes from 'prop-types';
import ClearButton from '../ClearButton';
import { ReactComponent as DownloadIcon } from '../../../img/icons/DownloadIcon.svg';
import './style.scss';

// renders a button which downloads a file from an external link and opens it in a new tab
const DownloadButton = ({ className, p, label, href, tracker, onClick, ariaLabel }) => (
  <div className={className}>
    <p>{p}</p>
    <ClearButton
      className="download-btn"
      label={label}
      ariaLabel={ariaLabel}
      icon={(
        <DownloadIcon
          width="16px"
          height="16px"
        />
      )}
      href={href}
      hoverEffect
      newTab
      tracker={tracker}
      onClick={onClick}
    />
  </div>
);

DownloadButton.propTypes = {
  className: PropTypes.string,
  // optional paragraph text describing what the download button does
  p: PropTypes.string,
  // the button label
  label: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string,
  // the download link
  href: PropTypes.string,
  onClick: PropTypes.func,
  // optional prop that's used by google analytics to track the number of times a file is downloaded
  tracker: PropTypes.shape({
    category: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
};

export default DownloadButton;
