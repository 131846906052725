import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import './style.scss';

const Table = ({ tableResponsive, thead, tbody, className, rowHeaderIndex, urlHeaderIndex, ariaLabel }) => {
  const columns = thead.map((data, index) => {
    const isDateColumn = data === 'Start Date' || data === 'End Date' || data === 'Publication Date';
    return (
      <th key={data} className={isDateColumn ? 'date' : ''} scope="col">
        {data}
      </th>
    );
  });

  const rows = tbody.map((rowData) => {
    const row = rowData.map((cell, index) => {
      if (rowHeaderIndex === index) {
        return (
          <th key={_.uniqueId()} scope="row">
            {cell}
          </th>
        );
      }
      else if (urlHeaderIndex === index) {
        return (
          <td key={_.uniqueId()} scope="row">
            <a href={cell} target="_blank">{cell}</a>
          </td>
        );
      }
      return <td key={_.uniqueId()}>{cell}</td>;
    });
    return <tr key={_.uniqueId()}>{row}</tr>;
  });
  return (
    <div className={tableResponsive ? 'table-responsive' : ''}>
      <table className={`table ${className}`} aria-label={ariaLabel ? ariaLabel : undefined}>
        <thead>
          <tr>{columns}</tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  className: PropTypes.string,
  thead: PropTypes.node.isRequired,
  tbody: PropTypes.node.isRequired,
  tableResponsive: PropTypes.bool,
  rowHeaderIndex: PropTypes.number,
};

Table.defaultTypes = {
  className: '',
  tableResponsive: false,
  rowHeaderIndex: null,
};

export default Table;
