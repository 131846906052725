/**
 * @file
 * This validation.js file provides a framework for validating fields in ReduxForm. Typically when validating
 * an input value you will have a bunch of if else statements to check if the input contains an error. This
 * framework attempts to streamline that process by providing reusable error checking which should
 * work for any ReduxForm.
 *
 * This framework uses error objects to check if a field has an error. When I say 'error object' I mean
 * an object with the following structure:
 * {
 *    error: (values, fieldPath) => {...},
 *    message: '...',
 * }
 * The error key is a function with two parameters: (1) values - object of all the values in the form, and (2) fieldPath - the path
 * to a field's value within the values object. The error function returns true if the field has an error, otherwise it returns false.
 *
 * The message key is the string that gets displayed if the error function returns true.
 * NOTE: If the message is null, then redux form thinks there are no errors with the field.
 *
 * You need to create an array of error objects for each field you want to validate - I call this the validationArray.
 * Validation is done by iterating over the validationArray and checking if any of the error objects return an error.
 */
/* eslint no-use-before-define: ["error", { "functions": false }] */

import _ from 'lodash';
import React from 'react';
import { FIELD_TYPES } from '../types';

/**
 * This function returns an error object to check if a value does not exist. If the error exists then the
 * default error message is "Please enter the fieldName"
 *
 * @param {string} fieldName name of the field
 * @param {string} customMessage a custom error message if you don't want to use the default error message
 */
export const fieldIsUndefined = (fieldName, customMessage) => ({
  error: (values, fieldPath) => !_.get(values, fieldPath),
  message: customMessage || `Please enter the ${fieldName}`,
});

/**
 * This function returns an error object to check if a string consists of empty spaces. If the error exists then
 * the default error message is "fieldName may not be blank"
 *
 * @param {string} fieldName name of the field
 * @param {string} customMessage a custom error message if you don't want to use the default error message
 */
export const textIsEmptyString = (fieldName, customMessage) => ({
  error: (values, fieldPath) => _.get(values, fieldPath).trim() === '',
  message: customMessage || `${fieldName} may not be blank`,
});


/**
 * This function returns an error object to check if a string exceeds a maximum length. If the error exists
 * then the default error message is "fieldName may not be longer than maxCount characters"
 *
 * @param {string} fieldName name of the field
 * @param {number} maxCount maximum allowed length of the string
 * @param {string} customMessage a custom error message if you don't want to use the default error message
 */
export const textExceedsMaxCount = (fieldName, maxCount, customMessage) => ({
  error: (values, fieldPath) => _.get(values, fieldPath).trim().length > maxCount,
  message: customMessage || `${fieldName} may not be longer than ${maxCount} characters`,
});

/**
 * This function returns a validation array that checks if a string input
 *
 * 1) is undefined
 *
 * 2) consists entirely of spaces
 *
 * 3) exceeds a given maximum length
 *
 * in that order. If you use this textValidationArray then you will not be able to use custom error messages.
 *
 * @param {string} fieldName name of the field
 * @param {number} maxCount maximum allowed length of the string
 */
export const textValidationArray = (fieldName, maxCount) => ([
  { ...fieldIsUndefined(fieldName) },
  { ...textIsEmptyString(fieldName) },
  { ...textExceedsMaxCount(fieldName, maxCount) },
]);

/**
 * This function returns a boolean value representing whether a zip code is a valid US postal code
 *
 * @param {string} zipCode zip code entered by user
 * @param {string} customMessage a custom error message if you don't want to use the default error message
 */
export const zipCodeValidation = customMessage => ({
  error: (values, fieldPath) => {
    const zipCode = _.get(values, fieldPath).trim();
    return !/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipCode);
  },
  message: customMessage || 'Zip Code is not a valid US Postal Code',
});

/**
 * This function checks if a Field has any validation errors. The function returns an error message if
 * a validation error exists, otherwise it returns null.
 *
 * @param {object} values object of all the values in the form
 * @param {string} valuePath path to the Field's value within the values object
 * @param {array} validationArray array of the Field's error objects
 */
export const validateField = (values, valuePath, validationArray) => {
  let errorMessage = null;
  validationArray.some(({ error, message }) => {
    if (error(values, valuePath)) {
      errorMessage = message;
      return true; // break out of the loop if there is an error
    }
    return false; // continue iterating over the array
  });
  return errorMessage;
};

/**
 * This function returns the error of either a field, form section, or field array.
 *
 * @param {object} values object of all the values in the form
 * @param {string} valuePath path to the field's value within the values object
 * @param {object} fields object of all the fields with their corresponding validation arrays
 * @param {string} fieldPath path to the field within the fields object
 */
export function getFieldError(values, valuePath, fields, fieldPath) {
  let error;
  const field = _.get(fields, fieldPath);
  if (_.isArray(field)) {
    error = validateField(values, valuePath, field);
  } else if (field.fieldType === FIELD_TYPES.FORM_SECTION) {
    error = validateFormSection(values, valuePath, fields, fieldPath);
  } else if (field.fieldType === FIELD_TYPES.FIELD_ARRAY) {
    error = validateFieldArray(values, valuePath, fields, fieldPath);
  }
  return error;
}

/**
 * This function checks if the Fields in a FormSection have any validation errors. If validation errors exist,
 * the function returns an object containing all the fields with validation errors, otherwise it returns null.
 *
 * @param {object} values object of all the values in the form
 * @param {string} valuePath path to the FormSection's value within the values object
 * @param {object} fields object of all the fields with their corresponding validation arrays
 * @param {string} formSectionPath path to the FormSection within the fields object
 */
export function validateFormSection(values, valuePath, fields, formSectionPath) {
  const errors = {};
  const sectionFields = _.get(fields, `${formSectionPath}.fields`);
  const shouldValidate = _.get(fields, `${formSectionPath}.shouldValidate`);
  if (sectionFields && shouldValidate(values, valuePath)) {
    if (_.isArray(sectionFields)) {
      return validateField(values, valuePath, sectionFields);
    }
    Object.keys(sectionFields).forEach((field) => {
      const fieldValuePath = `${valuePath}.${field}`;
      const fieldErrorPath = `${formSectionPath}.fields.${field}`;
      const error = getFieldError(values, fieldValuePath, fields, fieldErrorPath);
      if (error) {
        errors[field] = error;
      }
    });
  }
  return !_.isEmpty(errors) && errors;
}

/**
 * This function checks if the Fields in a FieldArray have any validation errors. If validation errors exist,
 * the function returns an array of objects containing all the fields with validation errors, otherwise it
 * returns null.
 *
 * @param {object} values object of all the values in the form
 * @param {string} valuePath path to the FieldArray's value within the values object
 * @param {object} fields object of all the fields with their corresponding validation arrays
 * @param {string} fieldArrayPath path to the FieldArray within the fields object
 */
export function validateFieldArray(values, valuePath, fields, fieldArrayPath) {
  const errors = [];
  const fieldArrayValues = _.get(values, valuePath);
  const shouldValidate = _.get(fields, `${fieldArrayPath}.shouldValidate`);
  if (fieldArrayValues && shouldValidate(values, valuePath)) {
    Object.keys(fieldArrayValues).forEach((member, index) => {
      const memberErrors = validateFormSection(values, `${valuePath}[${index}]`, fields, fieldArrayPath);
      if (!_.isEmpty(memberErrors)) {
        errors[index] = memberErrors;
      }
    });
  }
  return !_.isEmpty(errors) && errors;
}

/**
 * This function checks if there are any validation errors in a form. It returns an object of all the fields
 * with errors. If the object returned is empty then there are no validation errors.
 *
 * @param {object} values object of all the values in the form
 * @param {object} fields object of all the fields in the form that require validation
 */
export const validateForm = (values, fields) => {
  const errors = {};
  Object.keys(fields).forEach((field) => {
    const error = getFieldError(values, field, fields, field);
    if (error) {
      errors[field] = error;
    }
  });
  return errors;
};

/**
 * This function generates the alert error message for backend validation errors. This function
 * takes an optional options parameter if you want to render a list of all the fields with validation errors.
 * The options parameter must be an array of objects with the following structure { name: '...', index: ... }
 * where name is the field name to display and index is it's position in the list.
 *
 * @param {string} header the main message to display
 * @param {array} options optional array of all the fields with validation errors.
 */
export const renderValidationErrorMessage = (header, options) => {
  if (options && options.length > 0) {
    options.sort((a, b) => a.index - b.index);
    return (
      <div>
        <h3>{header}</h3>
        <ul>
          {options.map(option => <li key={option.name}>{option.name}</li>)}
        </ul>
      </div>
    );
  }
  return <h3>{header}</h3>;
};

/**
 * This function creates an object { name, index } and adds it to the options array
 * if an object with the same name and index does not already exist
 *
 * @param {array} options the array to append to
 * @param {string} name field name
 * @param {number} index the display order of the field name when rendering in the alert banner
 */
export const setOptions = (options, name, index) => {
  const option = {
    name,
    index,
  };
  if (_.findIndex(options, option) < 0) {
    options.push(option);
  }
};

/**
 * This is a helper function for validating submission forms. Because of the way the backend is set
 * up, some of the field values in submission have to be structured as a FormSection. For example
 * if we have a field called field1, typically we would just store the value to field1 as:
 *
 * values = {
 *   field1: 'field1 value'
 * };
 *
 * But because submission is so fucking janky, we now need to store it as:
 *
 * values = {
 *   field1: {
 *     value: 'field1 value'
 *   }
 * }
 *
 * This function takes a validation array for a field and returns a FormSection error object.
 * @param {*} validationArray the field's validation array
 */
export const convertToFormSection = validationArray => ({
  fieldType: FIELD_TYPES.FORM_SECTION,
  shouldValidate: () => true,
  fields: {
    value: validationArray,
  },
});
