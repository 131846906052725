import React from 'react';

const RequiredTag = () => <span className="requiredField">*</span>;

const RequiredFieldsWarning = () => (
  <p>
    All fields marked with an asterisk ( {RequiredTag()} ) are required.
  </p>
);

export { RequiredTag, RequiredFieldsWarning };
