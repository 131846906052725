import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ResearcherInfoWorkspace from './ResearcherInfoWorkspace';
import AffiliateUser from '../../../features/request/components/forms/researchTeam/components/AffiliateUser';
import { BlueButton } from '../../buttons';
import { scrollToTopOfDiv } from '../../utils/utils';
import { ALERT_TYPES } from '../../types';
import Alert from '../../alert';

// renders array of researcher info
const ResearcherInfoHOCWorkspace = (props) => {
  const {
    fields,
    researcher,
    institutions,
    titles,
    instructions,
    modalProps,
    researchers,
    setResearchers,
  } = props;

  const [alert, setAlert] = useState({ alertType: null, alertMessage: null });

  if (fields.length === 0) {
    fields.push({});
  }

  // close the alert dialog
  const resetAlert = () => {
    // only reset the alert if alertType and alertMessage are not null nor undefined
    if (alert.alertType || alert.alertMessage) {
      setAlert({
        alertType: null,
        alertMessage: null,
      });
    }
  };

  const removeForm = (fieldIndex) => {
    fields.remove(fieldIndex);
    resetAlert();
  };

  const addNewMember = (index, newMember) => {
    const {
      firstName,
      middleInitial,
      lastName,
      institutionName,
      emailAddress,
      title,
      projectRole,
      jobTitle,
    } = newMember;
    const newResearcher = {
      // Name field for AffilaiteUser component
      name: `${title ? `${title} ` : ''}${firstName}${
        middleInitial ? ` ${middleInitial}. ` : ' '
      }${lastName}`,
      institutionName,
      firstName,
      middleInitial,
      lastName,
      emailAddress,
      title,
      projectRole,
      jobTitle,
    };
    setResearchers([...researchers, newResearcher]);
    removeForm(index);
    scrollToTopOfDiv(`${researcher}-section`);

    setAlert({
      alertType: ALERT_TYPES.SUCCESS,
      alertMessage: `New ${researcher} has been added to research team`,
    });
  };

  const removeResearcher = (removedResearcher) => {
    setResearchers(
      researchers.filter(member => member.id !== removedResearcher.id),
    );

    setAlert({
      alertType: ALERT_TYPES.INFO,
      alertMessage: `${researcher} has been removed from research team`,
    });
  };

  const renderSelectedMembers = () => {
    if (researchers.length > 0) {
      return (
        <div>
          <p className="package-instructions">
            {instructions}&nbsp;<span className="requiredField">*</span>
          </p>
          <h3 className="form-heading-3">{`Added ${researcher}(s)`}</h3>
          <div className="list-group">
            {researchers.map((selectedResearcher) => {
              // selected researchers for associates and collaborators array does not
              // return with name attribute, have to build
              const {
                title,
                firstName,
                middleInitial,
                lastName,
              } = selectedResearcher;
              selectedResearcher.name = `${
                title ? `${title} ` : ''
              }${firstName}${
                middleInitial ? ` ${middleInitial}. ` : ' '
              }${lastName}`;
              return (
                <AffiliateUser
                  key={selectedResearcher.id}
                  remove={() => removeResearcher(selectedResearcher)}
                  user={selectedResearcher}
                />
              );
            })}
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <Alert
        alertType={alert.alertType}
        message={alert.alertMessage}
        onClose={resetAlert}
      />
      {renderSelectedMembers()}
      <br />
      <hr />
      <p>All fields marked with an asterisk ( <b style={{color: '#ef0000'}}> *</b> ) are required.</p>
      {fields.map((member, index) => (
        <ResearcherInfoWorkspace
          key={member}
          member={member}
          index={index}
          form={`${member}-form-${index}`}
          fields={fields}
          researcher={researcher}
          institutions={institutions}
          titles={titles}
          modalProps={modalProps}
          removeForm={removeForm}
          addNewMember={addNewMember}
          adjustedIndex={researchers.length + index}
          alert={alert}
          researchers={researchers}
        />
      ))}
      <BlueButton
        type="button"
        onClick={() => fields.push({})}
        label={`Add ${researcher}`}
        align="right"
      />
    </>
  );
};

ResearcherInfoHOCWorkspace.propTypes = {
  fields: PropTypes.object.isRequired,
  researcher: PropTypes.string.isRequired,
  institutions: PropTypes.shape({
    nih: PropTypes.arrayOf(PropTypes.object),
    other: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  titles: PropTypes.arrayOf(PropTypes.string).isRequired,
  instructions: PropTypes.string,
  modalProps: PropTypes.object,
  researchers: PropTypes.array,
  setResearchers: PropTypes.func.isRequired,
};

export default ResearcherInfoHOCWorkspace;
