import { get } from 'lodash';
import { fieldIsUndefined } from '../../../utils';
import { FIELD_TYPES } from '../../../types';

const zipMaxLength = 5;

// check if the createNew value for the instituion/division equals the given parameter
const checkCreateNew = createNewValue => ({
  error: (values, fieldPath) => {
    const parentPath = fieldPath.match(/^(.*?)\.[a-z]*$/)[1];
    const parentObj = get(values, parentPath);
    const createNew = parentObj ? parentObj.createNew : false;
    return createNew === createNewValue;
  },
  message: '',
});

export const institutionDivisionFields = type => ({
  id: [
    checkCreateNew(true), // if user is creating a new institution/division, then don't display any errors for the id field
    {
      error: (values, fieldPath) => !get(values, fieldPath),
      message: type === 'institution' ? 'Please select an institution' : 'Please select a division',
    },
  ],
  institutionType: {
    fieldType: FIELD_TYPES.FORM_SECTION,
    shouldValidate: () => type === 'institution',
    fields: {
      id: [fieldIsUndefined(null, 'Please enter your institution type')],
    },
  },
  name: [
    checkCreateNew(false), // if user is selecting an institution/division from the dropdown list, then don't display errors for the name field
    fieldIsUndefined(null, `Please enter your ${type} name`),
  ],
  isForProfit: [{
    error: ({ division }) => type === 'institution' && division && division.institution && division.institution.createNew && !division.institution.isForProfit,
    message: 'Please select whether your institution is for profit',
  }],
  address: {
    fieldType: FIELD_TYPES.FORM_SECTION,
    shouldValidate: (values, fieldPath) => {
      const parentPath = fieldPath.match(/^(.*?)\.[a-z]*$/)[1];
      const parentObj = get(values, parentPath);
      return parentObj ? parentObj.createNew : false;
    },
    fields: {
      country: [
        fieldIsUndefined(null, 'Please select a country'),
      ],
      address1: [
        fieldIsUndefined(null, 'Please enter an address'),
      ],
      city: [
        fieldIsUndefined(null, 'Please enter a city'),
      ],
      state: [
        {
          // only display validation error if country is United States of America
          error: (values, fieldPath) => {
            const addressPath = fieldPath.match(/^(.*?)\.[a-z]*$/)[1];
            const address = get(values, addressPath);
            return address && address.country === 'United States of America' && !address.state;
          },
          message: 'Please select a state',
        },
      ],
      zip: [
        {
          // only display validation error if country is United States of America
          error: (values, fieldPath) => {
            const addressPath = fieldPath.match(/^(.*?)\.[a-z]*$/)[1];
            const address = get(values, addressPath);
            return address && address.country === 'United States of America' && !address.zip;
          },
          message: 'Please enter a zip code',
        },
        {
          // only display validation error if country is United States of America
          error: (values, fieldPath) => {
            const addressPath = fieldPath.match(/^(.*?)\.[a-z]*$/)[1];
            const address = get(values, addressPath);
            return address && address.country === 'United States of America' && address.zip.length > zipMaxLength;
          },
          message: `Zip code may not be longer than ${zipMaxLength} digits`,
        },
      ],
    },
  },
});
