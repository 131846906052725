import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const DataChallengeSelectionModal = (props) => {
  const { isOpen, closeModal, unselect } = props;

  return (
    <div>
      <Modal isOpen={isOpen} toggle={closeModal}>
        <ModalHeader toggle={closeModal} tag="h3">
          Register as Data Challenge Participant
        </ModalHeader>
        <ModalBody>
          You have identified yourself as a participant with the NICHD Decoding
          Maternal Morbidity Data Challenge. Once you confirm your registration,
          we will automatically add nuMoM2b Challenge items to your cart.
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn modal-btn btn-secondary"
            onClick={() => {
              closeModal();
              unselect();
            }}
          >
            <span className="row" style={{ justifyContent: 'center' }}>I am NOT</span>
            <span className="row">a Challenge Participant</span>
            {/* No, I am not a Challenge Participant */}
          </button>
          <button
            type="button"
            className="btn modal-btn modal-btn-blue"
            style={{ textAlign: 'center', justifyContent: 'center' }}
            onClick={closeModal}
          >
            <span className="row" style={{ justifyContent: 'center', fontSize: 'medium' }}><center>I AM</center></span>
            <span className="row" style={{ fontSize: 'medium' }}>a Challenge Participant</span>
            {/* Yes, I am a Challenge Participant */}
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

DataChallengeSelectionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  unselect: PropTypes.func.isRequired,
};

export default DataChallengeSelectionModal;
