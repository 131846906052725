import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownMenu, DropdownItem } from 'reactstrap';
import { uniqueId } from 'lodash';
import { ReactComponent as DownArrowIcon } from '../../../img/icons/arrows/Down.svg';
import { ReactComponent as UpArrowIcon } from '../../../img/icons/arrows/Up.svg';
import NavButton from '../NavButton';
import './style.scss';

const ActionsDropdown = (props) => {
  const [dropdownOpen, toggleDropdownOpen] = useState(false);
  const { dropdownItems, buttonName, style, className, menuStyle, buttonClass } = props;
  const Icon = dropdownOpen ? UpArrowIcon : DownArrowIcon;
  return (
    <div style={style} className={`${className} actions-dropdown`}>
      <Dropdown className="sort-wrapper" isOpen={dropdownOpen} toggle={() => toggleDropdownOpen(!dropdownOpen)} inNavbar>
        <NavButton
          disabled={dropdownItems.length === 1}
          classes={buttonClass}
          label={(
            <span>
              {buttonName}
              <span className="caret-icon">
                {dropdownItems.length > 1 ? <Icon alt="arrow" style={{ height: '13px', width: '13px' }} stroke="black" /> : ''}
              </span>
            </span>
          )}
          onClick={() => toggleDropdownOpen(!dropdownOpen)}
          onKeyPress={() => toggleDropdownOpen(!dropdownOpen)}
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded={dropdownOpen}
          outside
        />
        <DropdownMenu style={menuStyle}>
          <ul style={{ listStyle: 'none' }} className="container">
            {dropdownItems.map(item => <li key={uniqueId()}><DropdownItem onClick={item.onClick}>{item.child}</DropdownItem></li>)}
          </ul>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

ActionsDropdown.defaultProps = {
  className: '',
  buttonName: 'Actions',
  style: {},
  dropdownItems: [],
  buttonClass: 'actions-button',
  menuStyle: {},
};

ActionsDropdown.propTypes = {
  dropdownItems: PropTypes.array,
  buttonName: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  buttonClass: PropTypes.string,
  menuStyle: PropTypes.object,
};

export default ActionsDropdown;
