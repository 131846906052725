import React from 'react';
import PropTypes from 'prop-types';
import { RequiredTag } from '../../other/RequiredFields';
import './style.scss';

const Textarea = (props) => {
  const {
    input,
    id,
    divClassName,
    className,
    labelClassName,
    label,
    note,
    rows,
    ariaLabel,
    placeholder,
    disabled,
    required,
    meta,
    ...rest
  } = props;

  const inputClassName = `form-control ${className}`;
  const asterisk = required ? <RequiredTag /> : null;
  const noteText = note && note.length > 0
    ? <p className="note"><b>Note: </b>{note} </p>
    : null;

  let textAreaStyle = {};
  let errorStyle = {};
  let errorMessage = '';
  if (meta && meta.touched && (meta.error || meta.warning)) {
    textAreaStyle = { border: '1px solid rgb(213, 0, 0)', boxShadow: '0 0 5px rgb(213, 0, 0)', marginBottom: '0px' };
    errorStyle = { color: 'rgb(213, 0, 0)' };
    errorMessage = meta.error || meta.warning;
  }
  return (
    <div className={divClassName}>
      { label && (
        <label htmlFor={id} className={labelClassName}>
          {label}&nbsp;
          {asterisk}
        </label>
      )}
      {noteText}
      <textarea
        {...input}
        {...rest}
        id={id}
        className={inputClassName}
        aria-label={ariaLabel || placeholder}
        placeholder={placeholder}
        disabled={disabled}
        rows={rows}
        aria-describedby={id}
        style={textAreaStyle}
      />
      <div style={errorStyle}>
        {errorMessage}
      </div>
    </div>
  );
};

Textarea.propTypes = {
  input: PropTypes.object,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  divClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  label: PropTypes.string,
  note: PropTypes.string,
  rows: PropTypes.number,
  ariaLabel: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
  }),
};

Textarea.defaultProps = {
  disabled: false,
  required: false,
};

export default Textarea;
