import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import ErrorLabel from './ErrorLabel';

const FieldErrorLabel = ({ name, className }) => (
  <Field
    name={name}
    className={className}
    component={ErrorLabel}
  />
);

FieldErrorLabel.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default FieldErrorLabel;
