import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { KEY_CODE, useWindowDimensions } from '../../utils';
import { ReactComponent as DownArrow } from '../../../img/icons/arrows/Down.svg';
import { ReactComponent as UpArrow } from '../../../img/icons/arrows/Up.svg';

// minimum viewport width in order for the onMouseEnter/onMouseLeave to work
export const minWidth = 1200;

const Dropdown = (props) => {
  const {
    name,
    links,
    dropdownStyle,
    selected,
    closeMainMenu,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const node = useRef();
  const { width } = useWindowDimensions();

  const toggleDropdown = () => setIsOpen(!isOpen);

  const constructLinks = (linkages, hasParent) => {
    const linksArray = [];

    linkages.forEach(({ href, label, children }) => {
      if (!children) {
        const type = hasParent ? 'child' : null;
        linksArray.push({ name: label.toLowerCase(), href, label, type });
      } else if (children && children.length > 0) {
        linksArray.push({ name: label.toLowerCase(), href, label, type: 'parent' });
        const childrenArray = constructLinks(children, true);
        linksArray.push(...childrenArray);
      }
    });

    return linksArray;
  };

  const linksArray = constructLinks(links);

  const handleOnMouseEnter = () => {
    if (width > minWidth) {
      setIsOpen(true);
    }
  };
  const handleOnMouseLeave = () => {
    if (width > minWidth) {
      setIsOpen(false);
    }
  };
  const handleLinkOnClick = () => {
    setIsOpen(false);
    if (width <= minWidth) {
      closeMainMenu();
    }
  };

  const handleKeydown = (e) => {
    switch (e.keyCode) {
      case KEY_CODE.TAB:
        if (isOpen) {
          // move focus to the first menu item
          e.preventDefault();
          node.current.lastChild.childNodes[0].focus();
        }
        break;
      case KEY_CODE.UP:
        // open the dropdown and move the focus to the last menu item
        e.preventDefault();
        setIsOpen(true);
        window.setTimeout(() => node.current.lastChild.childNodes[node.current.lastChild.childNodes.length - 1].focus(), 0);
        break;
      case KEY_CODE.ENTER:
      case KEY_CODE.SPACE:
      case KEY_CODE.DOWN:
        // open the dropdown and move the focus to the first menu item
        e.preventDefault();
        setIsOpen(true);
        window.setTimeout(() => node.current.lastChild.childNodes[0].focus(), 0);
        break;
      default:
    }
  };

  const handleItemKeydown = (e) => {
    switch (e.keyCode) {
      case KEY_CODE.TAB:
        setIsOpen(false);
        break;
      case KEY_CODE.ESCAPE:
        setIsOpen(false);
        node.current.firstChild.focus();
        break;
      case KEY_CODE.HOME:
        // move the focus to the first menu item
        e.preventDefault();
        node.current.childNodes[1].firstChild.focus();
        break;
      case KEY_CODE.END:
        // move the focus to the last menu item
        e.preventDefault();
        node.current.childNodes[1].lastChild.focus();
        break;
      case KEY_CODE.UP: {
        // move the focus to the previous menu item; if focus is on the first menu item, then move focus to the last menu item
        e.preventDefault();
        const nodeIndex = parseInt(e.target.getAttribute('index'), 10);
        let nextIndex = nodeIndex === 0 ? linksArray.length - 1 : nodeIndex - 1;
        nextIndex = node.current.childNodes[1].childNodes[nextIndex].getAttribute('tabIndex') !== '0' ? nextIndex - 1 : nextIndex;
        node.current.childNodes[1].childNodes[nextIndex].focus();
        break;
      }
      case KEY_CODE.DOWN: {
        // move the focus to the next menu item; if focus is on the last menu item, then move the focus to the first menu item
        e.preventDefault();
        const nodeIndex = parseInt(e.target.getAttribute('index'), 10);
        let nextIndex = nodeIndex === linksArray.length - 1 ? 0 : nodeIndex + 1;
        nextIndex = node.current.childNodes[1].childNodes[nextIndex].getAttribute('tabIndex') !== '0' ? nextIndex + 1 : nextIndex;
        node.current.childNodes[1].childNodes[nextIndex].focus();
        break;
      }
      default:
        break;
    }
  };

  const renderLinks = linkages => (
    linkages.map(({ href, label, type }, index) => {
      if (type === 'parent') {
        return (
          <div key={label} index={index} className="dropdown-item-parent">
            <div
              className="parent-title"
            >
              {label}
            </div>
          </div>
        );
      }
      if (type === 'child') {
        return (
          <Link
            key={label}
            to={href}
            index={index}
            tabIndex="0"
            onClick={handleLinkOnClick}
            onKeyPress={handleLinkOnClick}
            onKeyDown={e => handleItemKeydown(e)}
            className="dropdown-item child-item"
          >
            {label}
          </Link>
        );
      }
      return (
        <Link
          key={label}
          to={href}
          index={index}
          tabIndex="0"
          onClick={handleLinkOnClick}
          onKeyPress={handleLinkOnClick}
          onKeyDown={e => handleItemKeydown(e)}
          className="dropdown-item"
        >
          {label}
        </Link>
      );
    })
  );

  let show = '';
  let Icon = DownArrow;
  if (isOpen) {
    show = 'show';
    Icon = UpArrow;
  }
  return (
    <div
      className={`dash-nav-dropdown ${show}`}
      ref={node}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      <button
        type="button"
        className={selected ? 'dash-nav-link selected-path' : 'dash-nav-link'}
        onClick={toggleDropdown}
        aria-haspopup="true"
        aria-expanded={isOpen}
        onKeyDown={handleKeydown}
      >
        {name}
        <Icon
          height="13px"
          width="13px"
          stroke="white"
          style={{ marginLeft: '5px' }}
          title={`Collapse ${name}`}
        />
      </button>
      <div
        className={`dash-nav-dropdown-menu dropdown-menu mt-0 ${show}`}
        style={dropdownStyle}
      >
        {renderLinks(linksArray)}
      </div>
    </div>
  );
};

Dropdown.propTypes = {
  name: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(PropTypes.shape({
    href: PropTypes.string,
    label: PropTypes.string.isRequired,
    children: PropTypes.array,
  })).isRequired,
  dropdownStyle: PropTypes.object,
  selected: PropTypes.bool,
  closeMainMenu: PropTypes.func.isRequired,
};

Dropdown.defaultProps = {
  selected: false,
};

export default Dropdown;
