import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { MODAL_TYPES } from '../../types';
import Checkbox from '../../inputs/Checkbox';
import ConstraintsTable from '../components/ConstraintsTable';
import { RequiredFieldsWarning } from '../../other/RequiredFields/index';

// this modal renders on the cart page to notify the user that they have to
// comply with constraints for some of their biospecimen items
class ConstraintsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkboxValue: false,
      constraints: null, // array of all the constraints
      collapseToggles: {}, // keeps track of the Show/Hide collapse toggles for each constraint
      numSamples: 0, // total number of samples which have constraints
      type: null,
    };
    this.toggleCheckbox = this.toggleCheckbox.bind(this);
    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.removeSamples = this.removeSamples.bind(this);
  }

  componentDidMount() {
    // get data for all unresolved constraints and store it in the state
    const collapseToggles = {};
    let numSamples = 0;
    const constraints = this.props.checkConstraints;
    const type = this.props.checkConstraints[0].type;
    constraints.forEach(({ id, items }) => {
      collapseToggles[id] = false;
      numSamples += items.length;
    });
    this.setState(() => ({
      constraints,
      collapseToggles,
      numSamples,
      type,
    }));
  }

  toggleCheckbox() {
    this.setState(prevState => ({
      checkboxValue: !prevState.checkboxValue,
    }));
  }

  // open/close the table of biospecimen samples for the given constraint
  toggleCollapse(constraintId) {
    this.setState(prevState => ({
      collapseToggles: {
        ...prevState.collapseToggles,
        [constraintId]: !prevState.collapseToggles[constraintId],
      },
    }));
  }

  // remove all biospecimen samples for the given constraint
  removeSamples(constraintId, numSamplesRemoved) {
    this.setState(prevState => ({
      constraints: _.filter(prevState.constraints, constraint => constraint.id !== constraintId),
      numSamples: prevState.numSamples - numSamplesRemoved,
    }));
  }

  // create a new request if the user does not have any previous requests,
  // otherwise open the RequeseRequestModal
  handleNextStep(previousRequests) {
    this.props.closeModal();
    if (previousRequests.length === 0) {
      this.props.createRequest(this.props.studyId)
        .then((resp) => {
          this.props.history.push(`/request/${resp.id}`);
        });
    } else {
      const reuseRequestModalProps = {
        ...this.props,
        previousRequests,
      };
      this.props.openModal(MODAL_TYPES.REUSE_REQUEST_MODAL, reuseRequestModalProps);
    }
  }

  render() {
    const {
      isOpen,
      closeModal,
      getPreviousRequests,
      complyWithConstraints,
      removeItemsWithConstraints,
    } = this.props;

    if (!this.state.constraints) {
      return null;
    }

    // array of all biospecimen samples grouped by constraints
    const constraintTables = this.state.constraints.map(constraint => (
      <ConstraintsTable
        key={_.uniqueId()}
        {...constraint}
        collapseOpen={this.state.collapseToggles[constraint.id]}
        toggleCollapse={this.toggleCollapse}
        removeSamples={this.removeSamples}
        removeItemsWithConstraints={removeItemsWithConstraints}
        studyId={this.props.studyId}
      />
    ));

    const dataConstraints = (
      <div>
        <h3>Data use restrictions based on consent:</h3>
        <p>{this.state.constraints[0].restriction}</p>
      </div>
    );

    let paragraph;
    let type;
    let typeUpper;
    if (this.state.type === 'bio') {
      type = 'samples';
      typeUpper = 'Samples';
      paragraph = (
        <p className="mg-bt-0">
          You are requesting {this.state.numSamples} sample(s) that require compliance
          to participant&#39;s consent provisions. Please verify below whether your research
          is compliant with the following requirements. {/* If not, please remove the samples
          from your cart before proceeding to the request process. */}
        </p>
      );
    } else {
      type = 'data';
      typeUpper = 'Data';
      paragraph = (
        <p className="mg-bt-0">
        You are requesting data that require compliance
        to participant&#39;s consent provisions. Please verify below whether your research
        is compliant with the following requirements. {/* If not, please remove the data
        from your cart before proceeding to the request process. */}
        </p>
      );
    }

    return (
      <Modal
        isOpen={isOpen}
        toggle={closeModal}
        size="lg"
        backdrop="static"
        labelledBy="verify-compliance-modal"
      >
        <ModalHeader toggle={closeModal} tag="h2" id="verify-compliance-modal">
          Verify Compliance With Requested {typeUpper} Subject Consent Levels
        </ModalHeader>
        <ModalBody>
          <h3>Requested {type} consent levels</h3>
          {paragraph}
          <br />
          <hr />
          {this.state.type === 'bio' && constraintTables}
          {this.state.type === 'data' && dataConstraints}
          <br />
          <div className="center">
            <RequiredFieldsWarning />
            <Checkbox
              id="constraints-checkbox"
              label="I comply with all compliance statements"
              input={{
                value: this.state.checkboxValue,
                onChange: this.toggleCheckbox,
              }}
              className="constraints-modal-checkbox"
              required
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn modal-btn btn-secondary"
            aria-label="Cancel and close modal"
            onClick={closeModal}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn modal-btn modal-btn-blue"
            aria-label="Next - You must verify that you will be in compliance with the Requested Data Subject Consent Levels before proceeding."
            disabled={!this.state.checkboxValue}
            onClick={() => {
              complyWithConstraints(this.props.studyId)
                .then(() => {
                  getPreviousRequests()
                    .then(data => this.handleNextStep(data));
                });
            }}
          >
            Next
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}

ConstraintsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  getPreviousRequests: PropTypes.func.isRequired,
  createRequest: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  studyId: PropTypes.number.isRequired,
  getUnresolvedConstraints: PropTypes.func.isRequired,
  complyWithConstraints: PropTypes.func.isRequired,
  removeItemsWithConstraints: PropTypes.func.isRequired,
  checkConstraints: PropTypes.array.isRequired,
};

export default ConstraintsModal;
