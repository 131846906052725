import React from 'react';
import PropTypes from 'prop-types';
import { ClearButton } from '../../buttons';
import { ReactComponent as CloseIcon } from '../../../img/icons/CloseIcon.svg';
import { ReactComponent as TrashIcon } from '../../../img/icons/TrashIcon.svg';
import './style.scss';

const InputList = (props) => {
  const {
    title,
    list,
    type,
    remove,
    numbered,
    itemClassName,
    removeBtnClassName,
    useCloseIcon,
  } = props;

  if (!list || list.length === 0) {
    return null;
  }
  return (
    <>
      {title && <h3 className="form-heading-3">{title}</h3>}
      <ul className="list-group">
        {list.map((element, index) => (
          <li>
            <div key={element.id} className="row input-list-items">
              <div className={itemClassName}>
                {numbered
                  ? `${index + 1}. ${element.display}`
                  : element.display}
              </div>
              <div className={removeBtnClassName}>
                <ClearButton
                  type="button"
                  icon={
                    useCloseIcon ? (
                      <CloseIcon height="20px" width="20px" />
                    ) : (
                      <TrashIcon />
                    )
                  }
                  ariaLabel={`Remove ${props.type ? props.type : ''}: ${element.display}`}
                  onClick={() => remove(element, index)}
                />
              </div>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};

InputList.propTypes = {
  title: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    display: PropTypes.node.isRequired,
  })),
  remove: PropTypes.func.isRequired,
  numbered: PropTypes.bool,
  itemClassName: PropTypes.string,
  removeBtnClassName: PropTypes.string,
  useCloseIcon: PropTypes.bool,
};

InputList.defaultProps = {
  numbered: false,
  itemClassName: 'col-10 col-md-8 col-lg-6 input-list-display',
  removeBtnClassName: 'col-2 col-md-4',
  useCloseIcon: true,
};

export default InputList;
