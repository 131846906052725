import {
  SUBMISSION_SET_SUBMISSIONS,
  SUBMISSION_SET_SUBMISSION_STATUS,
  SUBMISSION_SET_STEP_DATA,
  SUBMISSION_SET_FORM_SUPPORT_DATA,
  SUBMISSION_SET_DIVISION_FORM_SUPPORT,
  SUBMISSION_RESET_STEP_DATA,
  SUBMISSION_SET_NEXT_STEP,
  SUBMISSION_CREATE_NEW_INSTITUTION,
  SUBMISSION_CREATE_NEW_DIVISION,
  SUBMISSION_RESET_STATE,
  SUBMISSION_SET_CURR_STEP_INDEX,
} from '../../common/types';

export const INITIAL_STATE = {
  userSubmissions: null,
  id: null, // submission id
  createdById: null, // id of the user that created the submission
  submissionType: null, // the type of submission
  steps: null, // array of all the forms the user has to go through; array order is the order of the forms to complete
  stepToIndexMap: null, // object of all the steps and their corresponding step number
  currStepIndex: null, // index of the current form the user is on
  nextStepToCompleteIndex: null, // index of the next uncompleted form, the user cannot advance to any forms with index greated than nextStepToCompleteIndex
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SUBMISSION_SET_SUBMISSIONS:
    case SUBMISSION_SET_SUBMISSION_STATUS:
    case SUBMISSION_SET_CURR_STEP_INDEX:
    case SUBMISSION_SET_NEXT_STEP:
      return {
        ...state,
        ...action.payload,
      };
    case SUBMISSION_SET_STEP_DATA: {
      const { currStepIndex, step, data } = action.payload;
      const prevStepObject = state[step] || {};
      return {
        ...state,
        currStepIndex,
        [step]: {
          ...prevStepObject,
          data,
        },
      };
    }
    case SUBMISSION_SET_FORM_SUPPORT_DATA: {
      const { step, formSupportKey, formSupportData } = action.payload;
      const stepObject = state[step] || {};
      const formSupportObject = stepObject[formSupportKey] || {};
      return {
        ...state,
        [step]: {
          ...stepObject,
          [formSupportKey]: {
            ...formSupportObject,
            ...formSupportData,
          },
        },
      };
    }
    case SUBMISSION_SET_DIVISION_FORM_SUPPORT: {
      const { step, institutionId, divisions } = action.payload;
      return {
        ...state,
        [step]: {
          ...state[step],
          formSupport: {
            ...state[step].formSupport,
            divisions: {
              ...state[step].formSupport.divisions,
              [institutionId]: divisions,
            },
          },
        },
      };
    }
    case SUBMISSION_CREATE_NEW_INSTITUTION: {
      const { step, institution } = action.payload;
      return {
        ...state,
        [step]: {
          ...state[step],
          formSupport: {
            ...state[step].formSupport,
            institutions: {
              NIH: [...state[step].formSupport.institutions.NIH],
              Other: [
                ...state[step].formSupport.institutions.Other,
                institution,
              ],
            },
          },
        },
      };
    }
    case SUBMISSION_CREATE_NEW_DIVISION: {
      const { step, institutionId, newDivision } = action.payload;
      const divisionArray = state[step].formSupport.divisions[institutionId] || [];
      return {
        ...state,
        [step]: {
          ...state[step],
          formSupport: {
            ...state[step].formSupport,
            divisions: {
              ...state[step].formSupport.divisions,
              [institutionId]: [
                ...divisionArray,
                newDivision,
              ],
            },
          },
        },
      };
    }
    case SUBMISSION_RESET_STEP_DATA: {
      const { [action.payload]: step, ...rest } = state; // remove the step data from the state
      return { ...rest };
    }
    case SUBMISSION_RESET_STATE:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};
