import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

// renders a reusable modal with two buttons: one to cancel/close the modal and another
// to perform some action
const DeleteOutcomeModal = (props) => {
  const {
    isOpen,
    closeModal,
    modalSize,
    modalHeaderTag,
    modalHeader,
    modalBodyTag,
    BodyComponent,
    cancelButtonText,
    actionButtonOnClick,
    cancelButtonOnClick,
    actionButtonText,
    disableActionButton,
    initialState,
    ...rest
  } = props;

  const [actionButtonIsDisabled, setActionButtonDisabled] = useState(disableActionButton);
  const [modalState, setModalState] = useState(initialState);

  // Reset the DeleteOutcomeModal states whenever the modal opens or closes. This fixes a bug when you open the DeleteOutcomeModal,
  // modify one of the state variables, close the DeleteOutcomeModal, and then open it again. On the second open, the values
  // of the two state variables (actionButtonIsDisabled, modalState) will remain the previous values
  useEffect(() => {
    setActionButtonDisabled(disableActionButton);
    setModalState(initialState);
  }, [isOpen, disableActionButton, initialState]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={closeModal}
      size={modalSize}
      backdrop="static"
    >
      <ModalHeader toggle={closeModal} tag={modalHeaderTag}>
        {modalHeader}
      </ModalHeader>
      <ModalBody tag={modalBodyTag}>
        <BodyComponent
          closeModal={closeModal}
          actionButtonIsDisabled={actionButtonIsDisabled}
          setActionButtonDisabled={setActionButtonDisabled}
          modalState={modalState}
          setModalState={setModalState}
          {...rest}
        />
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          className="btn modal-btn btn-secondary"
          onClick={() => {
            cancelButtonOnClick();
            closeModal();
          }}
        >
          {cancelButtonText}
        </button>
        { actionButtonText && (
          <button
            type="button"
            className="btn modal-btn modal-btn-blue"
            onClick={() => actionButtonOnClick(modalState)}
            disabled={actionButtonIsDisabled}
          >
            {actionButtonText}
          </button>
        )}
      </ModalFooter>
    </Modal>
  );
};

DeleteOutcomeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  modalHeader: PropTypes.string.isRequired,
  BodyComponent: PropTypes.func.isRequired,
  modalSize: PropTypes.string, // sets the modal size, value is either 'sm', 'md', 'lg, 'or 'xl'
  modalHeaderTag: PropTypes.string, // sets the html element of the ModalHeader section, i.e. 'h2', 'div', 'p', etc.
  modalBodyTag: PropTypes.string, // sets the html element of the ModalBody section, i.e. 'h2', 'div', 'p', etc.
  cancelButtonText: PropTypes.string,
  actionButtonText: PropTypes.string,
  actionButtonOnClick: PropTypes.func, // must be provided if you provide the actionButtonText
  disableActionButton: PropTypes.bool,
  initialState: PropTypes.any,
  cancelButtonOnClick: PropTypes.func.isRequired,
};

DeleteOutcomeModal.defaultProps = {
  modalSize: 'md',
  modalHeaderTag: 'h2',
  modalBodyTag: 'div',
  cancelButtonText: 'Cancel',
  disableActionButton: false,
  initialState: {},
};

export default DeleteOutcomeModal;
