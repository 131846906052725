import React from 'react';
import PropTypes from 'prop-types';
import { map, uniqueId } from 'lodash';
import { Field } from 'redux-form';
import { countries, states } from './constants';
import { InputBox, DropdownSelect } from '../../../inputs';

const AddressForm = ({ selectedCountry, disableFields }) => {
  const isUSA = () => selectedCountry === 'United States of America';

  const renderOptions = (options) => {
    const optionsArr = [];
    map(options, (name) => {
      optionsArr.push({ value: name, item: name });
    });
    return optionsArr;
  };

  const uniqueID = uniqueId();

  const renderState = () => {
    if (isUSA()) {
      return (
        <div className="form-group">
          <Field
            component={DropdownSelect}
            name="state"
            id={`state_${uniqueID}`}
            label="State"
            placeholder="State"
            options={renderOptions(states)}
            required
            disabled={disableFields}
          />
        </div>
      );
    }
    return (
      <div className="form-group">
        <Field
          component={InputBox}
          id={`state_${uniqueID}`}
          name="state"
          label="Province/Region"
          placeholder="Province/Region"
          disabled={disableFields}
        />
      </div>
    );
  };

  const renderZip = () => {
    if (isUSA()) {
      return (
        <div className="form-group">
          <Field
            component={InputBox}
            id={`zipcode_${uniqueID}`}
            name="zip"
            label="Zip Code"
            placeholder="Zip Code"
            required
            disabled={disableFields}
          />
        </div>
      );
    }
    return (
      <div className="form-group">
        <Field
          component={InputBox}
          id={`zipcode_${uniqueID}`}
          name="zip"
          label="Postal Code"
          placeholder="Postal Code"
          disabled={disableFields}
        />
      </div>
    );
  };

  return (
    <div>
      <div className="form-group">
        <Field
          component={DropdownSelect}
          name="country"
          id={`country_${uniqueID}`}
          label="Country"
          placeholder="Country"
          options={renderOptions(countries)}
          required
          disabled={disableFields}
        />
      </div>
      <div className="form-group">
        <Field
          component={InputBox}
          id={`address1_${uniqueID}`}
          name="address1"
          label="Address (Line 1)"
          placeholder="Address Line 1"
          required
          disabled={disableFields}
        />
      </div>
      <div className="form-group">
        <Field
          component={InputBox}
          id={`address2_${uniqueID}`}
          name="address2"
          label="Address (Line 2)"
          placeholder="Address Line 2"
          disabled={disableFields}
        />
      </div>
      <div className="row">
        <div className="col-sm-4">
          <div className="form-group">
            <Field
              component={InputBox}
              id={`city_${uniqueID}`}
              name="city"
              label="City"
              placeholder="City"
              required
              disabled={disableFields}
            />
          </div>
        </div>
        <div className="col-sm-4">
          {renderState()}
        </div>
        <div className="col-sm-4">
          {renderZip()}
        </div>
      </div>
    </div>
  );
};

AddressForm.propTypes = {
  selectedCountry: PropTypes.string,
  disableFields: PropTypes.bool,
};

export default AddressForm;
