import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { FileUpload } from '../../../../../common/inputs';
import { validateUploadExecutedAgreementForm } from './AdminRequestValidator';

export const UploadExecutedRenewalForm = (props) => {
  return (
    <form onSubmit={props.handleSubmit}>
      <div>
        Please upload the Executed Renewal Form file for this request.
      </div>
      <div className="form-group p-15">
        <Field
          component={FileUpload}
          name="uploadRequestFileForm"
          id="executed-renewal-form"
          label="Executed Renewal Form"
          required
        />
      </div>
    </form>
  );
};

UploadExecutedRenewalForm.propTypes = {
  handleSubmit: PropTypes.func,
};

export default reduxForm({
  form: 'uploadExecutedRenewalForm',
  validate: validateUploadExecutedAgreementForm,
})(UploadExecutedRenewalForm);
