
import {
  GET_DATASET_OVERVIEW,
  SET_VARIABLE_PAGINATION,
  DATASET_OVERVIEW_RESET_STATE,
  ADD_TO_CART_DATASET_OVERVIEW,
  RESET_DATASET_OVERVIEW,
  SET_TOTAL_VARIABLE_COUNTS,
} from '../../common/types';


export const initialState = {
  datasetOverview: {},
  coded: {
    page: {},
    items: null,
    counts: {},
  },
  numeric: {
    page: {},
    items: null,
    counts: {},
  },
  character: {
    page: {},
    items: null,
    counts: {},
  },
  dateTime: {
    page: {},
    items: null,
    counts: {},
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_DATASET_OVERVIEW:
      return {
        ...state,
        datasetOverview: action.payload,
      };
    case SET_VARIABLE_PAGINATION: {
      const { type, page, items } = action.payload;
      return {
        ...state,
        [type]: {
          ...state[type],
          page,
          items,
        },
      };
    }
    case SET_TOTAL_VARIABLE_COUNTS: {
      const { type, numRecords, totalElements } = action.payload;
      return {
        ...state,
        [type]: {
          ...state[type],
          counts: {
            numRecords,
            totalElements,
          },
        },
      };
    }
    case DATASET_OVERVIEW_RESET_STATE:
      return {
        ...initialState,
      };
    case ADD_TO_CART_DATASET_OVERVIEW:
      return {
        ...state,
        datasetOverview: {
          ...state.datasetOverview,
          canAddItem: false,
        },
      };
    case RESET_DATASET_OVERVIEW:
      return initialState;
    default:
      return state;
  }
};
