import React from 'react';
import PropTypes from 'prop-types';
import {
  FormNavButton,
  YellowButton,
} from '../../../buttons';

// Component that renders the previous, submit, and next buttons at the bottom of the form
const FormFooter = (props) => {
  const {
    updateStep,
    previous,
    disablePrevBtn,
    submit,
    submitBtnType,
    disableSubmitBtn,
    next,
    disableNextBtn,
    justifyContentEnd,
    className,
  } = props;

  const formNavClassName = submit ? 'col-4' : 'col-6';

  const renderPreviousBtn = () => (previous || previous === 0) && (
    <div className={formNavClassName}>
      <FormNavButton
        type="button"
        label="Previous"
        disabled={disablePrevBtn}
        onClick={() => updateStep(previous)}
      />
    </div>
  );

  const renderSubmitBtn = () => {
    if (!submit) {
      return null;
    }

    // type of button to render
    let Button;
    switch (submitBtnType) {
      case 'YellowButton':
        Button = YellowButton;
        break;
      default:
        Button = FormNavButton;
    }

    return (
      <div className="col-4 text-center">
        <Button
          type="submit"
          label={submit}
          disabled={disableSubmitBtn}
          className={className}
        />
      </div>
    );
  };

  const renderNextBtn = () => next && (
    <div className={`${formNavClassName} text-right`}>
      <FormNavButton
        type="button"
        label="Next"
        disabled={disableNextBtn}
        onClick={() => updateStep(next)}
      />
    </div>
  );

  return (
    <div className={justifyContentEnd ? 'row justify-content-end form-footer' : 'row form-footer'}>
      {renderPreviousBtn()}
      {renderSubmitBtn()}
      {renderNextBtn()}
    </div>
  );
};

FormFooter.propTypes = {
  updateStep: PropTypes.func.isRequired, // function to navigate to the previous or next step
  previous: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // api endpoint of previous step in the form
  disablePrevBtn: PropTypes.bool,
  submit: PropTypes.string, // submit button name
  submitBtnType: PropTypes.string, // type of button to display for submit
  disableSubmitBtn: PropTypes.bool,
  next: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // api endpoint of the next step in the form
  disableNextBtn: PropTypes.bool,
  justifyContentEnd: PropTypes.bool,
};

FormFooter.defaultProps = {
  justifyContentEnd: true,
};

export default FormFooter;
