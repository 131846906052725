import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { FileUpload } from '../../../../../common/inputs';
import { validateUploadExecutedAgreementForm } from './AdminRequestValidator';

export const UploadExecutedUseAgreementForm = (props) => {
  const label = props.requestType === 'Biospecimen' ? 'Executed Material Transfer Agreement' : 'Executed Data Use Agreement';
  return (
    <form onSubmit={props.handleSubmit}>
      <div>
        Please upload the {label} file for this request.
      </div>
      <div className="form-group p-15">
        <Field
          component={FileUpload}
          name="uploadRequestFileForm"
          id="executed-use-agreement"
          label={label}
          required
        />
      </div>
    </form>
  );
};

UploadExecutedUseAgreementForm.propTypes = {
  handleSubmit: PropTypes.func,
  requestType: PropTypes.string,
};

export default reduxForm({
  form: 'uploadRequestFileForm',
  validate: validateUploadExecutedAgreementForm,
})(UploadExecutedUseAgreementForm);
