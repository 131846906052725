import _ from 'lodash';
import {
  WORKSPACE_SET_INITIAL_DATA,
  WORKSPACE_SET_DATA,
  SET_DUA_ITEMS,
  GET_AFFILIATES,
  GET_RESEARCH_TEAM,
  DUA_SET_SELECTED_ITEMS,
  DUA_SET_COLLAPSE_OPEN,
  UPDATE_COUNTS,
  WORKSPACE_CREATE_NEW_INSTITUTION,
} from '../../common/types';

const INITIAL_STATE = {
  duaData: {},
  mtaData: {},
  inboxData: {},
  counts: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case WORKSPACE_SET_INITIAL_DATA: {
      const { counts, inboxData, mtaData, duaData } = action.payload;
      return {
        ...state,
        counts,
        inboxData,
        mtaData,
        duaData,
      };
    }
    case WORKSPACE_SET_DATA:
      return {
        ...state,
        [action.dataType]: action.payload,
      };
    case SET_DUA_ITEMS: {
      const { duaId, page, items, collapseOpen, dataType } = action.payload;
      return {
        ...state,
        [dataType]: {
          ...state[dataType],
          pagination: {
            ...state[dataType].pagination,
            [duaId]: {
              ...state[dataType].pagination[duaId],
              page,
              items,
              collapseOpen,
            },
          },
        },
      };
    }
    case GET_AFFILIATES: {
      const { id, affiliates, userData, dataType } = action.payload;
      return {
        ...state,
        [dataType]: {
          ...state[dataType],
          pagination: {
            ...state[dataType].pagination,
            [id]: {
              ...state[dataType].pagination[id],
              affiliates,
              users: userData,
            },
          },
        },
      };
    }
    case GET_RESEARCH_TEAM: {
      const { id, affiliates, associates, collaborators, userData, dataType, institutions, institutionTypes, titles } = action.payload;
      return {
        ...state,
        [dataType]: {
          ...state[dataType],
          pagination: {
            ...state[dataType].pagination,
            [id]: {
              ...state[dataType].pagination[id],
              affiliates,
              associates,
              collaborators,
              users: userData,
            },
          },
          formSupport: {
            institutions,
            titles,
            institutionTypes,
          },
        },
      };
    }
    case WORKSPACE_CREATE_NEW_INSTITUTION: {
      const { dataType, institution } = action.payload;
      return {
        ...state,
        [dataType]: {
          ...state[dataType],
          formSupport: {
            ...state[dataType].formSupport,
            institutions: {
              ...state[dataType].formSupport.institutions,
              Other: [
                ...state[dataType].formSupport.institutions.Other,
                institution,
              ],
            },
          },
        },
      };
    }
    case DUA_SET_SELECTED_ITEMS: {
      const { duaId, rowSelected, dataType, selectAllItemsOption } = action.payload;
      const selectedItems = { ...state[dataType].pagination[duaId].selectedItems };
      if (_.has(selectedItems, [rowSelected.archiveEntityId]) && !selectAllItemsOption) {
        delete selectedItems[rowSelected.archiveEntityId];
      } else {
        selectedItems[rowSelected.archiveEntityId] = rowSelected;
      }
      return {
        ...state,
        [dataType]: {
          ...state[dataType],
          pagination: {
            ...state[dataType].pagination,
            [duaId]: {
              ...state[dataType].pagination[duaId],
              selectedItems,
            },
          },
        },
      };
    }
    case DUA_SET_COLLAPSE_OPEN: {
      const { duaId, collapseOpen, dataType } = action.payload;
      return {
        ...state,
        [dataType]: {
          ...state[dataType],
          pagination: {
            ...state[dataType].pagination,
            [duaId]: {
              ...state[dataType].pagination[duaId],
              collapseOpen,
            },
          },
        },
      };
    }
    case UPDATE_COUNTS: {
      return {
        ...state,
        counts: {
          ...state.counts,
          inboxData: action.numInboxItems,
        },
      };
    }
    default:
      return state;
  }
};
