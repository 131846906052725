import React, { useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import {
  DatePicker,
  InputBox,
  SearchSelect,
  Textarea,
} from '../../../../common/inputs';
import { ClearButton } from '../../../../common/buttons';
import { ReactComponent as TrashIcon } from '../../../../img/icons/TrashIcon.svg';

// only allow user to enter one character for middle initial
export const parseMiddleInitial = (value) => {
  if (value.match(/^[a-zA-Z]{0,1}$/)) {
    return value;
  }
  return undefined;
};


// renders the input fields (first name, last name, etc) for one researcher
const PublicationForm = (props) => {
  const {
    member,
    index,
    fields,
    type,
    setDisabled,
    label,
    maxDate,
    minDate,
  } = props;


  // Please pay attention to the month (parts[1]); JavaScript counts months from 0:
  // January - 0, February - 1, etc.
  // const initialDate = new Date(parts[0], parts[1] - 1, parts[2]);

  const [selectedDate, setSelectedDate] = useState(maxDate);

  const renderRemoveBtn = () => {
    if (fields.length > 1) {
      return (
        <ClearButton
          type="button"
          onClick={() => fields.remove(index)}
          onKeyPress={() => fields.remove(index)}
          label={`Remove ${label}`}
          align="right"
          icon={<TrashIcon />}
        />
      );
    }
    return null;
  };

  let sectionHeader;
  if (type === 'significantfinding') {
    sectionHeader = 'SIGNIFIGANT FINDINGS';
  } else if (type === 'otheroutcomes') {
    sectionHeader = 'OTHER OUTCOMES';
  } else {
    sectionHeader = `${_.toUpper(type)}s`;
  }

  return (
    <div>
      <div className="row">
        <h2 className="form-heading-1"> {sectionHeader} OUTCOME INFORMATION {index + 1}</h2>
        <div className="col align-self-end">
          {renderRemoveBtn()}
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-md-6">
          <Field
            component={InputBox}
            id="publication-title"
            name={`${member}.title`}
            placeholder="Please enter publication title (300 characters including spaces)"
            label="Publication Title"
            rows={1}
            required
          />
        </div>
        <div className="col-md-6">
          <Field
            component={InputBox}
            id="publication-description"
            name={`${member}.description`}
            label="Citation (Use NLM citation format)"
            placeholder="Please enter the publication citation (700 characters including spaces)"
            rows={1}
            required
          />
        </div>
      </div>
      <div className="form-group">
        <div className="row">
          <div className="col-md-6">
            <Field
              component={InputBox}
              id="publication-journal"
              name={`${member}.journal`}
              label="Journal Name (Spell out Journal Name. Do not use abbreviations)"
              ariaLabel="Please enter the journal name (200 characters including spaces). The journal name should be spelled out, do not use abbreviations."
              placeholder="Please enter the journal name (200 characters including spaces)"
              required
            />
          </div>
          <div className="col-md-6">
            <Field
              id="publication-date"
              label="Publication Date (If publication does not use a specific publication date, please enter the first date of the month the journal is published (i.e., 11/01/2021))"
              name={`${member}.date`}
              ariaLabel="Please enter the publication date in the format mm/dd/yyyy. If publication does not use a specific publication date, please enter the first date of the month the journal is published (i.e., 11/01/2021)"
              form="annualReport"
              required
              // below props are used by ReactDatePicker
              selected={selectedDate}
              maxDate={maxDate}
              minDate={minDate}
              component={DatePicker}
              placeholderText="Select the publication date"
              dateFormat="MMMM d, yyyy"
              onChange={date => setSelectedDate(date)}
              className="form-control"
              popperClassName="mt_-20"
              errorPopperClassName="mt-25"
              showYearDropdown
              showMonthDropdown
              dropdownMode="select"
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Field
              component={Textarea}
              id="publication-authors"
              name={`${member}.authors`}
              label="Author(s) Name(s) (Provide full names (i.e., Janice Smith, Franklin W. Doe))"
              ariaLabel="Please enter the author(s) name(s) (500 characters including spaces).  Provide full names (i.e., Janice Smith, Franklin W. Doe)"
              placeholder="Please enter the author(s) name(s) (500 characters including spaces)"
              rows={1}
              required
            />
          </div>
          <div className="col">
            <Field
              component={InputBox}
              id="publicationURL"
              name={`${member}.publicationURL`}
              label="Publication URL (Provide Pubmed URL, if available)"
              placeholder="Please enter the publication URL (256 characters including spaces)"
              required
            />
          </div>
        </div>
      </div>
    </div>
  );
};

PublicationForm.propTypes = {
  member: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  fields: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  minDate: PropTypes.instanceOf(Date).isRequired,
  maxDate: PropTypes.instanceOf(Date).isRequired,
};

export default PublicationForm;
