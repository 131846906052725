import { getFlatDataFromTree, getTreeFromFlatData } from 'react-sortable-tree';

const studyRegistrationFormatter = (values) => {
  const submitValues = { ...values };
  // refactor division and network name values because they had to be modified when initializing the form
  if (submitValues.division) {
    submitValues.division = {};
    submitValues.division.id = values.division.id;
    submitValues.division.value = values.division.value.value;
  }
  if (submitValues.networkName) {
    submitValues.networkName = {};
    submitValues.networkName.id = values.networkName.id;
    submitValues.networkName.value = values.networkName.value.value;
  }

  // reset relatedStudiesInDash and relatedStudiesOutOfDash arrays if user selected 'No' for 'Related Studies in DASH' and 'Related Studies Outside of DASH' respectively
  if (!submitValues.hasRelatedStudiesInDash || submitValues.hasRelatedStudiesInDash.value === 'No') {
    submitValues.relatedStudiesInDash = [];
  }
  if (!submitValues.hasRelatedStudiesOutOfDash || submitValues.hasRelatedStudiesOutOfDash.value === 'No') {
    submitValues.relatedStudiesOutOfDash = [];
  }
  return submitValues;
};

const contactInformationFormatter = (values) => {
  const submitValues = { ...values };
  if (submitValues.studyPI) {
    if (submitValues.studyPI.radio === 'NICHD Point of Contact') {
      submitValues.studyPI.user = submitValues.pointOfContact.user;
    } else if (submitValues.studyPI.radio === 'Submitter') {
      submitValues.studyPI.user = submitValues.submitter;
    }
  }
  return submitValues;
};


const policyComplianceFormatter = (values) => {
  const submitValues = { ...values };
  Object.keys(submitValues).forEach((key) => {
    if ((submitValues[key].other && submitValues[key].answer === 'Yes') || !(/\S/.test(submitValues[key].other))) {
      delete submitValues[key].other;
    }
  });
  return submitValues;
};

const studyInformationFormatter = (values) => {
  const submitValues = { ...values };

  // convert the date fields from Date object to string in format yyyy-mm-dd
  const dateFields = ['enrollmentStart', 'enrollmentEnd', 'collectionStart', 'collectionEnd'];
  if (submitValues.hasStudyTimeline === 'Yes') {
    dateFields.forEach((dateField) => {
      if (submitValues[dateField]) {
        submitValues[dateField] = {
          ...submitValues[dateField],
          value: submitValues[dateField].value ? submitValues[dateField].value.toISOString().substring(0, 10) : null,
        };
      }
    });
    submitValues.reason = null;
  } else if (submitValues.hasStudyTimeline === 'No') {
    dateFields.forEach((dateField) => {
      submitValues[dateField] = null;
    });
    submitValues.reason = { ...submitValues.reason };
    submitValues.reason.value = submitValues.reason.value || '';
  }

  if (submitValues.studyHasBiospecimens?.value === 'No' && submitValues.biospecimenShareable) {
    // only send studyHasBiospecimens value if user selects 'No' for first question
    submitValues.biospecimenShareable = null;
  }

  // remove any urls which are empty
  const urlFields = ['studyWebsiteUrl', 'clinicalTrialsUrl', 'dbGAPUrl'];
  urlFields.forEach((field) => {
    if (submitValues[field] && !submitValues[field].value) {
      delete submitValues[field];
    }
  });
  return submitValues;
};

const studySchemaFormatter = (values) => {
  const flatData = getFlatDataFromTree({
    treeData: values.schemaTree,
    getNodeKey: ({ node }) => node.id,
    ignoreCollapsed: false,
  }).map((treeNode) => {
    const nodeValues = {
      description: typeof treeNode.node.subtitle === 'object' ? treeNode.node.subtitle.props.value : treeNode.node.subtitle,
      text: typeof treeNode.node.title === 'object' ? treeNode.node.title.props.value : treeNode.node.title,
    };
    Object.keys(nodeValues).forEach((key) => {
      if (/^\s*$/.test(nodeValues[key])) {
        delete nodeValues[key];
      }
    });
    delete treeNode.node.subtitle;
    return {
      ...nodeValues,
      nodeId: treeNode.node.id,
      parentNodeId: treeNode.parentNode ? treeNode.parentNode.id : 0,
    };
  },
  );
  const formattedTree = getTreeFromFlatData({
    flatData,
    getKey: node => node.nodeId,
    getParentKey: node => node.parentNodeId,
    rootKey: '0',
  });
  return { text: 'rootNode', children: formattedTree };
};

const biospecimenInfoFormatter = (values) => {
  const submitValues = { ...values };
  if (submitValues.studyHasBiospecimens && submitValues.studyHasBiospecimens.value === 'No') {
    // only send studyHasBiospecimens value if user selects 'No' for first question
    return {
      studyHasBiospecimens: submitValues.studyHasBiospecimens,
    };
  } else if (submitValues.biospecimenShareable && submitValues.biospecimenShareable.value === 'No') {
    // only send studyHasBiospecimens and biospecimenShareable value if user selects 'No' for second question
    return {
      studyHasBiospecimens: submitValues.studyHasBiospecimens,
      biospecimenShareable: submitValues.biospecimenShareable,
    };
  } else if (submitValues.selectedRepository && submitValues.selectedRepository !== 'Other') {
    return {
      studyHasBiospecimens: submitValues.studyHasBiospecimens,
      biospecimenShareable: submitValues.biospecimenShareable,
      biospecimenRepository: {
        ...submitValues.biospecimenRepository,
        value: submitValues.selectedRepository,
      },
    };
  }
  return submitValues;
};

const dataDistributionFormatter = (values) => {
  const submitValues = { ...values };
  if (submitValues.submissionDistribution) {
    if (submitValues.submissionDistribution.limitations) {
      if (submitValues.submissionDistribution.limitations === 'true') {
        submitValues.submissionDistribution = {
          ...submitValues.submissionDistribution,
          limitations: true,
        };
      } else {
        submitValues.submissionDistribution = {
          ...submitValues.submissionDistribution,
          limitations: false,
          other: null,
        };
      }
    }
    if (submitValues.submissionDistribution.irbApproval) {
      submitValues.submissionDistribution = {
        ...submitValues.submissionDistribution,
        irbApproval: submitValues.submissionDistribution.irbApproval === 'true',
      };
    }
  }

  if (submitValues.useLicensedCodingStandards && submitValues.useLicensedCodingStandards.value === 'No') {
    submitValues.licensedCodingStandards = null;
  }

  if (submitValues.useProprietaryInstruments && submitValues.useProprietaryInstruments.value === 'No') {
    submitValues.proprietaryInstruments = null;
  }

  if (submitValues.acknowledgmentStatement && submitValues.acknowledgmentStatement.value === '') {
    submitValues.acknowledgmentStatement = null;
  }
  return submitValues;
};

const studyPopulationFormatter = (values) => {
  const { usLocations, internationalLocations, ...submitValues } = values;
  // combine the usLocations and internatinalLocations values into one object
  submitValues.AggregatebyLocation = {
    ...usLocations,
    ...internationalLocations,
  };
  return submitValues;
};

const instCertFormatter = (values) => {
  const formData = new FormData();
  if (values.institutionalCert && values.institutionalCert.fileKey && values.institutionalCert.files) {
    formData.append(values.institutionalCert.fileKey, values.institutionalCert.files[0], values.institutionalCert.files[0].name);
  }
  if (values.biospecimenInstitutionalCert && values.biospecimenInstitutionalCert.fileKey && values.biospecimenInstitutionalCert.files) {
    formData.append(values.biospecimenInstitutionalCert.fileKey, values.biospecimenInstitutionalCert.files[0], values.biospecimenInstitutionalCert.files[0].name);
  }
  return formData;
};

// format the data when saving study registration step for federated submission
const studyInfoPartOneFormatter = (values) => {
  const submitValues = { ...values };
  // reset relatedStudiesInDash and relatedStudiesOutOfDash arrays if user selected 'No' for 'Related Studies in DASH' and 'Related Studies Outside of DASH' respectively
  if (!submitValues.hasRelatedStudiesInDash || submitValues.hasRelatedStudiesInDash.value === 'No') {
    submitValues.relatedStudiesInDash = [];
  }
  if (!submitValues.hasRelatedStudiesOutOfDash || submitValues.hasRelatedStudiesOutOfDash.value === 'No') {
    submitValues.relatedStudiesOutOfDash = [];
  }
  if (submitValues.repositoryName && submitValues.repositoryName.value !== 'Other') {
    submitValues.repositoryNameOther = null;
  }
  return submitValues;
};

const dataCollectionFormatter = (values) => {
  const submitValues = { ...values };
  if (submitValues.division) {
    submitValues.division = {};
    submitValues.division.id = values.division.id;
    submitValues.division.value = values.division.value.value;
  }
  if (submitValues.repositoryName && submitValues.repositoryName.value !== 'Other') {
    submitValues.repositoryNameOther = null;
  }

  if (submitValues.species) {
    const formattedSpecies = submitValues.species.map(({ value }) => ({
      other: value !== 'Species A' && value !== 'Species B' && value !== 'Species C',
      legendItem: { value },
    }));
    submitValues.species = formattedSpecies;
  }
  return submitValues;
};


export const formatSubmissionData = (step, values) => {
  let formattedData = null;
  switch (step) {
    case 'study-registration':
      formattedData = studyRegistrationFormatter(values);
      break;
    case 'investigator-information':
      formattedData = contactInformationFormatter(values);
      break;
    case 'policy-compliance':
      formattedData = policyComplianceFormatter(values);
      break;
    case 'study-details':
      formattedData = studyInformationFormatter(values);
      break;
    case 'study-schema':
      formattedData = studySchemaFormatter(values);
      break;
    case 'biospecimen-info':
      formattedData = biospecimenInfoFormatter(values);
      break;
    case 'data-distribution':
      formattedData = dataDistributionFormatter(values);
      break;
    case 'study-population':
      formattedData = studyPopulationFormatter(values);
      break;
    case 'upload-documents':
      formattedData = instCertFormatter(values);
      break;
    case 'study-info-part-one':
      formattedData = studyInfoPartOneFormatter(values);
      break;
    case 'study-info-part-two':
      formattedData = studyInformationFormatter(values);
      break;
    case 'data-collection':
      formattedData = dataCollectionFormatter(values);
      break;
    default:
      formattedData = values;
      break;
  }
  return formattedData;
};
