import React from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import Dropzone from "react-dropzone";
import { RequiredTag } from "../../other/RequiredFields";
import { ReactComponent as UploadIcon } from "../../../img/icons/UploadIcon.svg";
import { ReactComponent as InformationIcon } from "../../../img/icons/InformationIcon.svg";
import "./style.scss";

// File input component that allows the user to upload a single file at a time
const FileUpload = (props) => {
  const {
    input,
    meta: { touched, error, warning },
    id,
    label,
    required,
  } = props;

  let display = "";
  if (input.value.files && input.value.files.length > 0) {
    display = input.value.files[0].name;
  } else if (input.value.filename) {
    display = input.value.filename;
  }

  let tooltip = props.tooltip ? props.tooltip : (<p><span>Accepted File Types: .pdf, .doc, .docx, .jpg, .zip, .xls, .xlsx, .csv, .txt{" "}</span><br></br><span>File Size Limit: 10 MB</span></p>);

  let divStyle = {};
  let errorStyle = {};
  let errorMessage = "";
  if (touched && (error || warning)) {
    divStyle = {
      border: "1px solid rgb(213, 0, 0)",
      boxShadow: "0 0 5px rgb(213, 0, 0)",
      marginBottom: "0px",
    };
    errorStyle = { color: "rgb(213, 0, 0)" };
    errorMessage = error || warning;
  }
  const asterisk = required ? <RequiredTag /> : null;
  return (
    <div className="input-upload">
      <label aria-labelledby={id}>
        <h4>
          <b>{label}&nbsp;{asterisk}{" "}</b>
          <InformationIcon
            role="img"
            title="Accepted File Types: .pdf, .doc, .docx, .jpg, .zip, .xls, .xlsx, .csv, .txt, File Size limit 10MB"
            width="16px"
            height="16px"
            fill="none"
            strokeWidth="1px"
            stroke="gray"
            focusable
            data-tip
            data-for={`upload-package-tooltip-${id}`}
            aria-labelledby={`upload-package-tooltip-${id}`}
          />
        </h4>
      </label>

      <ReactTooltip
        id={`upload-package-tooltip-${id}`}
        className="information-icon-tooltip"
        place="right"
        type="light"
        effect="solid"
        event="mouseenter focus"
        eventOff="mouseleave focusout"
        aria-label="Accepted File Types: .pdf, .doc, .docx, .jpg, .zip, .xls, .xlsx, .csv, .txt"
        border
      >
        {tooltip}
      </ReactTooltip>
      <Dropzone
        {...input}
        multiple={false}
        onDrop={(filesToUpload) => {
          const data = { files: filesToUpload, fileKey: props.id };
          input.onChange(data);
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            id={id}
            {...getRootProps()}
            className="dropzone"
            role="group"
            aria-label={display ? `Re-upload ${label}. This field is required.` : `Upload ${label}. This field is required.`}
            style={divStyle}
          >
            <input {...getInputProps()} />
            <div className="btn upload-btn">
              <UploadIcon width="16px" height="16px" title={display ? `Re-upload ${label}` : `Upload ${label}`} />
              {display ? `Re-upload ${label}` : `Upload ${label}`}
            </div>
            <div className="btn upload-text" title={display}>
              <span>{display}</span>
            </div>
          </div>
        )}
      </Dropzone>

      <div style={errorStyle}>{errorMessage}</div>
    </div>
  );
};

FileUpload.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.any,
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
    warning: PropTypes.string,
  }).isRequired,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

export default FileUpload;
