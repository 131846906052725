import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Field,
  FieldArray,
  change,
} from 'redux-form';
import { RadioButton } from '../../../common/inputs';
import FieldErrorLabel from '../../../common/form/components/FieldErrorLabel';
import PublicationInfoHOC from './PublicationInfoHOC';
import * as ModalActions from '../../../common/modal/ModalActions';
import { MODAL_TYPES } from '../../../common/types';

export const OutcomeSelection = (props) => {
  const {
    type,
    prompt,
    initialValue,
    _change,
    values,
    ...rest
  } = props;


  const [newOutcome, setnewOutcome] = useState(false);

  useEffect(() => {
    setnewOutcome(props.values[`has${type}s`] ? props.values[`has${type}s`] === 'true' : initialValue === 'true');
  });

  let sectionHeader;
  if (type === 'significantfinding') {
    sectionHeader = 'SIGNIFIGANT FINDINGS';
  } else if (type === 'otheroutcome') {
    sectionHeader = 'OTHER OUTCOMES';
  } else {
    sectionHeader = `${_.toUpper(type)}s`;
  }

  const clearClicked = () => {
    const modalProps = {
      modalHeader: 'Delete Outcomes',
      BodyComponent: () => (
        <div>By clicking "Confirm" you are deleting previously entered outcomes</div>
      ),
      actionButtonText: 'Confirm',
      actionButtonOnClick: () => {
        _change('annualReport', `${type}s`, [{}]);
        setnewOutcome(false);
        props.closeModal();
      },
      cancelButtonOnClick: () => {
        _change('annualReport', `has${type}s`, 'true');
      },
    };
    if (newOutcome) {
      props.openModal(MODAL_TYPES.DELETE_OUTCOME_MODAL, modalProps);
    }
  };

  const renderPublications = () => {
    if (newOutcome) {
      const fieldArrayName = `${_.toLower(type)}s`;
      return (
        <div className="form-group">
          <div className="row">
            <div className="col-12 col-sm">
              <section>
                <FieldArray
                  name={fieldArrayName}
                  component={PublicationInfoHOC}
                  props={{ ...rest, type }}
                />
              </section>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  const selectorName = `has${props.type}s`;


  return (
    <div>
      <h2 className="form-heading-1">{sectionHeader}</h2>
      <hr />
      <p className="package-instructions">
        {prompt}&nbsp;<span className="requiredField">*</span>
      </p>
      <fieldset className="form-group">
        <div className="row align-items-center justify-content-center inline-checkbox-row" role="radiogroup">
          <div className="col-sm-3 py-2">
            <Field
              label="Yes"
              value="true"
              type="radio"
              component={RadioButton}
              name={selectorName}
              ariaLabel={`were there any ${sectionHeader} from secondary use this year? Selection of Yes creates additional required fields. Yes`}
              onClick={() => setnewOutcome(true)}
            />
          </div>
          <div className="col-sm-3 py-2">
            <Field
              label="No"
              value="false"
              type="radio"
              component={RadioButton}
              name={selectorName}
              ariaLabel={`were there any ${sectionHeader} from secondary use this year? Selection of Yes creates additional required fields. No`}
              onClick={() => clearClicked()}
            />
          </div>
        </div>
        <FieldErrorLabel
          name={selectorName}
          className="research-team-error"
        />
      </fieldset>
      {renderPublications()}
    </div>
  );
};

OutcomeSelection.propTypes = {
  type: PropTypes.string.isRequired,
  prompt: PropTypes.string.isRequired,
  initialValue: PropTypes.string,
  _change: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

const mapStateToProps = ({ form }) => ({
  values: form.annualReport.values,
});

const actions = {
  _change: change,
  openModal: ModalActions.openModal,
  closeModal: ModalActions.closeModal,
};


export default connect(mapStateToProps, actions)(OutcomeSelection);
