import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  FieldArray,
} from 'redux-form';
import ResearcherInfoHOCWorkspace from './ResearcherInfoHOCWorkspace';

// this component renders the researcher info for the Associates and Collaborator sections
export const ResearcherSectionWorkspace = (props) => {
  const { researcher, note, ...rest } = props;

  const fieldArrayName = `${_.toLower(researcher)}`;

  return (
    <div>
      <FieldArray
        name={fieldArrayName}
        component={ResearcherInfoHOCWorkspace}
        props={{ ...rest, researcher }}
      />
    </div>
  );
};

ResearcherSectionWorkspace.propTypes = {
  researcher: PropTypes.string.isRequired, // either 'Associate' or 'Collaborator'
  note: PropTypes.string,
};

export default connect()(ResearcherSectionWorkspace);
