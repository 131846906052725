import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import Alert from '../../../../../common/alert';
import { scrollToTop } from '../../../../../common/utils';
import FormFooter from '../../../../../common/form/components/FormFooter';
import OutcomeSelection from '../../OutcomeSelection';
import { validateAnnualReport } from './AnnualReportValidation';
import PrepopulatedData from '../PrepopulatedData';
import { Textarea } from '../../../../../common/inputs';
import CollapseToggle from '../../../../../common/other/CollapseToggle';

const AnnualReportForm = (props) => {
  const {
    handleSubmit,
    updateStep,
    data,
    alert,
    displayFooter,
  } = props;

  /* =========== REPORT YEAR =========== */
  const reportYear = (
    <div id="reportYear">
      {/* <h3 className="form-heading-3">Report Year</h3> */}
      <p><b>Report Year:&nbsp;</b>Annual Report Year {data.reportYear}</p>
      <br />
    </div>
  );


  return (
    <form onSubmit={handleSubmit}>
      <Alert
        alertType={alert?.alertType}
        message={alert?.alertMessage}
        allowClose={false}
      />
      <h2 className="form-heading-1">REQUEST INFORMATION</h2>
      <hr />
      {reportYear}

      <CollapseToggle
        labelClose="Show Request Details"
        labelOpen="Hide Details"
        collapseHeadClass="clear-btn"
        textColor="#045395"
        onClick={() => {}}
        hasIcon
        type="button"
        iconStyle={{ width: '16px', height: '16px' }}
      >
        <PrepopulatedData
          data={data}
        />
      </CollapseToggle>

      <OutcomeSelection
        type="publication"
        prompt="Were there any publications from secondary use this year?"
        initialValue={props.initialValues.haspublications}
        effectiveDate={data.effectiveDate}
      />
      <OutcomeSelection
        type="presentation"
        prompt="Were there any presentations from secondary use this year?"
        initialValue={props.initialValues.haspresentations}
        effectiveDate={data.effectiveDate}
      />
      <OutcomeSelection
        type="patent"
        prompt="Were there any patents from secondary use this year?"
        initialValue={props.initialValues.haspatents}
        effectiveDate={data.effectiveDate}
      />
      <OutcomeSelection
        type="significantfinding"
        prompt="Were there any significant findings from secondary use this year?"
        initialValue={props.initialValues.hassignificantfindings}
        effectiveDate={data.effectiveDate}
      />
      <OutcomeSelection
        type="otheroutcome"
        prompt="Were there any other outcomes from secondary use this year?"
        initialValue={props.initialValues.hasotheroutcomes}
        effectiveDate={data.effectiveDate}
      />
      <hr />
      <Field
        component={Textarea}
        id="additionalInformation"
        name="additionalInformation"
        label="Please provide additional information about outcomes; such as, manuscripts pending review and publication, your plans for future publications or applications to make a presentation."
        placeholder="Please enter any additional comments (500 characters including spaces)"
      />
      {displayFooter && (
      <FormFooter
        submit="Save"
        next="review-and-submit"
        updateStep={updateStep}
      />
      )}
    </form>
  );
};

AnnualReportForm.propTypes = {
  data: PropTypes.object,
  handleSubmit: PropTypes.func,
  updateStep: PropTypes.func,
  alert: PropTypes.object,
  initialValues: PropTypes.shape({
    haspublications: PropTypes.string,
    haspatents: PropTypes.string,
    hassignificantfindings: PropTypes.string,
    hasotheroutcomes: PropTypes.string,
    haspresentations: PropTypes.string,
  }),
  displayFooter: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'annualReport',
  warn: validateAnnualReport,
  onSubmitFail: () => {
    scrollToTop();
  },
  onSubmitSuccess: () => {
    scrollToTop();
  },
})(AnnualReportForm);
