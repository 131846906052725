import React, { useState } from "react";
import { FormSection, reduxForm, Field } from "redux-form";
import { Collapse } from "reactstrap";
import PropTypes from "prop-types";
import AddressForm from "../address/AddressForm";
import { InputBox, RadioButton, DropdownSelect } from "../../../inputs";
import Alert from "../../../alert";
import FieldErrorLabel from "../../components/FieldErrorLabel";
import { validateInstitution } from "../institution/InstitutionValidation";
import { ALERT_TYPES } from "../../../types";
import { ReactComponent as DownArrowIcon } from "../../../../img/icons/arrows/Down.svg";
import { ReactComponent as UpArrowIcon } from "../../../../img/icons/arrows/Up.svg";

export const AdminInstitutionForm = (props) => {
  const {
    error,
    disableFields,
    statuses,
    change,
    initialValues,
    institutionTypes,
  } = props;
  const [showInfo, toggleShowInfo] = useState(false);
  const [disableIsForProfit, setDisableIsForProfit] = useState(
    institutionTypes.find(
      (type) => type.name === initialValues.institutionType,
    ) != null,
  );

  const handleIsForProfitOnChange = (e, newValue) => {
    let selectedType = institutionTypes.find(
      (type) => type.name === newValue,
    ).isForProfit;
    if (selectedType != null) {
      selectedType = selectedType.toString();
      setDisableIsForProfit(true);
    } else {
      setDisableIsForProfit(false);
    }
    change("isForProfit", selectedType);
  };

  return (
    <form>
      <Alert alertType={ALERT_TYPES.ERROR} message={error} />
      <Field
        component={InputBox}
        id='institution-name'
        name='name'
        label='Institution Name'
        placeholder='Institution Name'
        required
        disabled={disableFields}
      />
      <button
        type='button'
        className='p-0 fs-14 mr-0 ml-auto d-block'
        onClick={() => toggleShowInfo(!showInfo)}
        aria-expanded={showInfo}
        aria-controls='admin-institution-address'
      >
        {!showInfo ? (
          <>
            Show Institution Address{" "}
            <DownArrowIcon height='13px' stroke='black' />
          </>
        ) : (
          <>
            Hide Institution Address{" "}
            <UpArrowIcon height='13px' stroke='black' />
          </>
        )}
      </button>
      <Collapse id='admin-division-address' isOpen={showInfo}>
        <div className='row form-group'>
          <div className='col-sm-12'>
            <Field
              component={DropdownSelect}
              id='institutionType'
              name='institutionType'
              label='Institution Type'
              placeholder='Select Institution Type'
              options={institutionTypes.map(({ name }) => ({
                item: name,
                value: name,
              }))}
              onChange={handleIsForProfitOnChange}
              required
              disabled={disableFields}
            />
          </div>
        </div>
        <label htmlFor='institutionStatus'>
          Institution Status <b style={{ color: "#ef0000" }}>*</b>
        </label>
        <div
          className='row align-items-center justify-content-center form-group'
          id='institutionStatus'
        >
          <div className='col-sm-4'>
            <Field
              component={RadioButton}
              label='For profit'
              value='true'
              type='radio'
              name='isForProfit'
              required
              disabled={disableFields || disableIsForProfit}
            />
          </div>
          <div className='col-sm-4'>
            <Field
              component={RadioButton}
              label='Not for profit'
              value='false'
              type='radio'
              name='isForProfit'
              required
              disabled={disableFields || disableIsForProfit}
            />
          </div>
          <FieldErrorLabel
            name='isForProfit'
            className='institution-type-error'
          />
        </div>
        <FormSection name='address'>
          <AddressForm disableFields={disableFields} />
        </FormSection>
      </Collapse>
      {!disableFields && (
        <Field
          component={DropdownSelect}
          id='status'
          label='Status'
          placeholder='Status'
          name='status'
          options={statuses.map((s) => ({ value: s, item: s }))}
        />
      )}
    </form>
  );
};

AdminInstitutionForm.propTypes = {
  error: PropTypes.string,
  disableFields: PropTypes.bool,
  statuses: PropTypes.array,
  initialValues: PropTypes.object,
  change: PropTypes.func,
  institutionTypes: PropTypes.array,
};

export default reduxForm({
  form: "adminInstitutionForm",
  warn: validateInstitution
})(AdminInstitutionForm);
