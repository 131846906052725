import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactComponent as WorkspaceIcon } from '../../../img/icons/WorkspaceIcon.svg';
import { ReactComponent as CartIcon } from '../../../img/icons/Cart.svg';
import { ReactComponent as UpdateIcon } from '../../../img/icons/UpdateIcon.svg';
import { ReactComponent as SignoutIcon } from '../../../img/icons/SignoutIcon.svg';

// renders navigation bar of user's account (workspace, cart, profile)
const AccountNavbar = (props) => {
  const {
    className,
    isNIHSSO,
    numCartItems,
  } = props;

  return (
    <ul className={`navbar-nav ${className}`}>
      <li>
        <Link
          to="/workspace"
          className="account-nav-link"
        >
          <WorkspaceIcon
            height="15px"
            width="15px"
            className="account-nav-icon"
            title="Link to workspace"
          />
          <span className="account-nav-label">Workspace</span>
        </Link>
      </li>
      <li aria-live="polite">
        <Link
          to="/cart"
          className="account-nav-link"
        >
          <CartIcon
            height="15px"
            width="15px"
            className="account-nav-icon"
            title="Link to Cart"
          />
          <span className="account-nav-label">My Cart</span>
          <span
            className="my-cart-count align-middle"
            hidden={numCartItems === -1}
          >
            {numCartItems}
          </span>
        </Link>
      </li>
      <li>
        <Link
          to="/profile"
          className="account-nav-link"
        >
          <UpdateIcon
            height="15px"
            width="15px"
            className="account-nav-icon"
            title="Link to update profile"
          />
          <span className="account-nav-label">{isNIHSSO ? 'View My Profile' : 'Update My Profile'}</span>
        </Link>
      </li>
      <li>
        <Link
          to="/logout"
          className="account-nav-link"
        >
          <SignoutIcon
            height="15px"
            width="15px"
            className="account-nav-icon"
            title="sign out of your account"
          />
          <span className="account-nav-label">Sign Out</span>
        </Link>
      </li>
    </ul>
  );
};

AccountNavbar.propTypes = {
  className: PropTypes.string,
  isNIHSSO: PropTypes.bool.isRequired,
  numCartItems: PropTypes.number.isRequired,
};

AccountNavbar.defaultProps = {
  className: '',
};

const mapStateToProps = ({ auth, header }) => ({
  isNIHSSO: auth.isNIHSSO,
  numCartItems: header.numCartItems,
});

export default connect(mapStateToProps)(AccountNavbar);
