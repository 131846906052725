import {
  textValidationArray,
  validateForm,
} from '../../../utils';
import { addressErrors } from '../address/AddressValidation';
import { FIELD_TYPES } from '../../../types';

const divisionNameMaxLength = 256;

const divisionFields = {
  name: textValidationArray('Division name', divisionNameMaxLength),
  address: {
    fieldType: FIELD_TYPES.FORM_SECTION,
    shouldValidate: () => true,
    fields: addressErrors,
  },
};

export const validateDivision = values => validateForm(values, divisionFields);
