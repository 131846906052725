import React from 'react';
import { Helmet } from 'react-helmet';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

const DAPRoute = ({ component, path, exact, privileged }) => {
  const script = (
    <Helmet>
      <script async type="text/javascript" src="https://dap.digitalgov.gov/Universal-Federated-Analytics-Min.js?agency=HHS&subagency=NIH" id="_fed_an_ua_tag" />
    </Helmet>
  );

  return (
    <>
      {!privileged && script}
      <Route path={path} component={component} exact={exact} />
    </>
  );
};

DAPRoute.propTypes = {
  component: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  privileged: PropTypes.bool.isRequired,
};

export default DAPRoute;
