import { SUBMISSION_TYPES } from '../../../common/types';

// this function returns the display name for all of the submission types
export const getSubmissionDisplayType = (submissionType) => {
  switch (submissionType) {
    case SUBMISSION_TYPES.INTEGRATED:
      return 'Submit study to DASH';
    case SUBMISSION_TYPES.FEDERATED:
      return 'Catalog study in DASH';
    case SUBMISSION_TYPES.COLLECTION:
      return 'Catalog external resource in DASH';
    default:
      return '';
  }
};

const integratedStepToIndexMap = {
  'Study Registration': 0,
  'Investigator Information': 1, // Contact Information step
  'Policy Compliance Questionnaire': 2,
  'Study Legend': 3, // Study Information step
  'Study Schema': 4,
  'Study Population': 5,
  'Biospecimen Info': 6,
  'File/Biospecimen Legends': 7, // Data Distribution step
  'Supporting Documents': 8, // Institutional Certification step
  'Data Files': 9, // Data Preparation step
  'Admin Policy Compliance Approval': 10, // Review and Submit step
  'Content Review/Adjustments': 11, // step name after submitting
};

const federatedStepToIndexMap = {
  'Study-Info-Part-One': 0, // Study Registration
  'Study-Info-Part-Two': 1, // Study Information
  'Study Schema': 2,
  'Study Population': 3,
  'Admin Policy Compliance Approval': 4, // Review and Submit
  'Content Review/Adjustments': 5, // after submitting
};

const collectionStepToIndexMap = {
  'Data Collection': 0, // Data Collection Information
  'Admin Policy Compliance Approval': 1, // Review and Submit
  'Content Review/Adjustments': 2, // after submitting
};

// returns the stepToIndexMap for the given submission type
export const getStepToIndexMap = (submissionType) => {
  switch (submissionType) {
    case SUBMISSION_TYPES.INTEGRATED:
      return integratedStepToIndexMap;
    case SUBMISSION_TYPES.FEDERATED:
      return federatedStepToIndexMap;
    case SUBMISSION_TYPES.COLLECTION:
      return collectionStepToIndexMap;
    default:
      return {};
  }
};

export const integratedSubmissionSteps = id => ([
  {
    stepName: 'Study Registration',
    formName: 'study-registration',
    to: `/submission/${id}/studyRegistration`,
  },
  {
    stepName: 'Contact Information',
    formName: 'investigator-information',
    to: `/submission/${id}/contactInformation`,
  },
  {
    stepName: 'Policy Compliance',
    formName: 'policy-compliance',
    to: `/submission/${id}/policyCompliance`,
  },
  {
    stepName: 'Study Information',
    formName: 'study-details',
    to: `/submission/${id}/studyInformation`,
  },
  {
    stepName: 'Study Schema',
    formName: 'study-schema',
    to: `/submission/${id}/studySchema`,
  },
  {
    stepName: 'Study Population',
    formName: 'study-population',
    to: `/submission/${id}/studyPopulation`,
  },
  {
    stepName: 'Biospecimen Information',
    formName: 'biospecimen-info',
    to: `/submission/${id}/biospecimenInformation`,
  },
  {
    stepName: 'Data Distribution',
    formName: 'data-distribution',
    to: `/submission/${id}/dataDistribution`,
  },
  {
    stepName: 'Institutional Certification',
    formName: 'upload-documents',
    to: `/submission/${id}/institutionalCertification`,
  },
  {
    stepName: 'Data Preparation',
    formName: 'data-preparation',
    to: `/submission/${id}/dataPreparation`,
  },
  {
    stepName: 'Review and Submit',
    formName: 'study-preview',
    to: `/submission/${id}/reviewAndSubmit`,
  },
]);

export const federatedSubmissionSteps = id => ([
  {
    stepName: 'Study Registration',
    formName: 'study-info-part-one',
    to: `/submission/${id}/federated/studyRegistration`,
  },
  {
    stepName: 'Study Information',
    formName: 'study-info-part-two',
    to: `/submission/${id}/federated/studyInformation`,
  },
  {
    stepName: 'Study Schema',
    formName: 'study-schema',
    to: `/submission/${id}/federated/studySchema`,
  },
  {
    stepName: 'Study Population',
    formName: 'study-population',
    to: `/submission/${id}/federated/studyPopulation`,
  },
  {
    stepName: 'Review and Submit',
    formName: 'study-preview-federated',
    to: `/submission/${id}/federated/reviewAndSubmit`,
  },
]);

export const collectionSubmissionSteps = id => ([
  {
    stepName: 'External Resource',
    formName: 'data-collection',
    to: `/submission/${id}/collection/dataCollectionInformation`,
  },
  {
    stepName: 'Review and Submit',
    formName: 'study-preview-collection',
    to: `/submission/${id}/collection/reviewAndSubmit`,
  },
]);

// returns the submission steps for the given submission type
export const getSubmissionSteps = (submissionType, id) => {
  switch (submissionType) {
    case SUBMISSION_TYPES.INTEGRATED:
      return integratedSubmissionSteps(id);
    case SUBMISSION_TYPES.FEDERATED:
      return federatedSubmissionSteps(id);
    case SUBMISSION_TYPES.COLLECTION:
      return collectionSubmissionSteps(id);
    default:
      return [];
  }
};

// this function returns the submission title given the submissionType and submissionName
export const getSubmissionTitle = (submissionType, submissionName, abbreviation) => {
  let label = '';
  switch (submissionType) {
    case SUBMISSION_TYPES.INTEGRATED:
      label = 'Submission';
      break;
    case SUBMISSION_TYPES.FEDERATED:
      label = 'Catalog Submission';
      break;
    case SUBMISSION_TYPES.COLLECTION:
      label = 'External Resource Cataloging Submission';
      break;
    default:
      return '';
  }
  if (submissionName && abbreviation) {
    return `${label}: ${submissionName} (${abbreviation})`;
  } else if (submissionName) {
    return `${label}: ${submissionName}`;
  }
  return label;
};
