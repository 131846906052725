import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReuseRequestForm from '../components/ReuseRequestForm';
// import { REQUEST_TYPES } from '../../types';

// modal that renders on the cart page to give the user the option of reusing data from a previous request
const ReuseRequestModal = (props) => {
  const {
    isOpen,
    closeModal,
    previousRequests,
    createRequest,
    createRequestFromPreviousRequest,
    submitReduxForm,
    history,
    studyId,
    // requestType,
    usePreviousRequest,
    selectedRequest,
  } = props;

  const goToRequest = (resp) => {
    closeModal();
    history.push(`/request/${resp.id}`);
  };

  const handleSubmit = (values) => {
    if (values.usePreviousRequest === 'true') {
      createRequestFromPreviousRequest(studyId, values.selectedRequest)
        .then(goToRequest);
    } else {
      createRequest(studyId)
        .then(goToRequest);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={closeModal}
      backdrop="static"
      size="lg"
      labelledBy='reuse-request-modal'
    >
      <ModalHeader toggle={closeModal} tag="h2" id='reuse-request-modal'>
        Use Information From Previous Request(s)
      </ModalHeader>
      <ModalBody>
        <p>All fields marked with an asterisk ( <b style={{color: '#ef0000'}}> *</b> ) are required.</p>
        <p>
          Would you like to use the same information from your previous DASH request(s)
          for data and/or biospecimens? You may edit the fields once they are pre-populated. The
          following sections in the request process will be pre-populated:
          <span><b style={{color: '#ef0000'}}> *</b></span>
        </p>
        <ul>
          <li>General</li>
          <li>Study Information</li>
          <li>Research Team (for data requests only)</li>
        </ul>
        <ReuseRequestForm
          usePreviousRequest={usePreviousRequest}
          formSupport={previousRequests}
          onSubmit={handleSubmit}
        />
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          className="btn modal-btn btn-secondary"
          aria-label="Cancel request submission."
          onClick={closeModal}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn modal-btn modal-btn-blue"
          aria-label={!((usePreviousRequest === 'false') || ((usePreviousRequest === 'true') && selectedRequest)) ? 'Select Yes or No above to start request process.': 'Start request process'}
          disabled={!((usePreviousRequest === 'false') || ((usePreviousRequest === 'true') && selectedRequest))}
          onClick={() => submitReduxForm('reuseRequestForm')}
        >
          Start Request Process
        </button>
      </ModalFooter>
    </Modal>
  );
};

ReuseRequestModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  createRequestFromPreviousRequest: PropTypes.func.isRequired,
  createRequest: PropTypes.func.isRequired,
  submitReduxForm: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  previousRequests: PropTypes.arrayOf(PropTypes.object).isRequired,
  studyId: PropTypes.number.isRequired,
  // requestType: PropTypes.string.isRequired,
  usePreviousRequest: PropTypes.string, // received from redux mapStateToProps
  selectedRequest: PropTypes.string, // received from redux mapStateToProps
};

const mapStateToProps = (state) => {
  const selector = formValueSelector('reuseRequestForm');
  return {
    usePreviousRequest: selector(state, 'usePreviousRequest'),
    selectedRequest: selector(state, 'selectedRequest'),
  };
};

export default connect(mapStateToProps)(ReuseRequestModal);
