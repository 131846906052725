import React from 'react';
import PropTypes from 'prop-types';
import {
  Spinner,
  Svg,
  Circle,
  Content,
  Overlay,
} from './styles';

const Loader = (props) => {
  let spinnerNode = null;
  if (props.spinner) {
    spinnerNode = (
      <Spinner spinnerSize={props.spinnerSize}>
        <Svg
          viewBox="25 25 50 50"
          position={props.fullPage ? 'relative' : 'absolute'}
        >
          <Circle
            color={props.color}
            cx="50"
            cy="50"
            r="20"
            fill="none"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </Svg>
      </Spinner>
    );
  }

  let textNode = null;
  if (props.text) {
    textNode = <div>{props.text}</div>;
  }

  let contentNode = null;
  if (props.text || props.spinner) {
    contentNode = (
      <Content>
        {spinnerNode}
        {textNode}
      </Content>
    );
  }

  return (
    <Overlay
      position={props.fullPage ? 'fixed' : 'absolute'} // make spinner fixed to coverage entire page, otherwise make absolute to only cover containing component
      background={props.background}
      color={props.color}
      speed={props.speed}
      zIndex={props.zIndex}
      key="dimmer"
      onClick={props.onClick}
    >
      {contentNode}
    </Overlay>
  );
};

Loader.propTypes = {
  spinner: PropTypes.bool,
  spinnerSize: PropTypes.string,
  color: PropTypes.string,
  text: PropTypes.string,
  background: PropTypes.string,
  speed: PropTypes.number,
  zIndex: PropTypes.number,
  onClick: PropTypes.func,
  fullPage: PropTypes.bool.isRequired, // true if you want to loader to cover full page, false if you want loader to only cover the wrapped components
};

Loader.defaultProps = {
  text: null,
  spinner: false,
  onClick: null,
};

export default Loader;
