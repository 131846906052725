import React from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import UserForm from '../user/UserForm';
import AdminInstitutionForm from './AdminInstitutionForm';
import AdminDivisionForm from './AdminDivisionForm';
import RolesForm from './RolesForm';
import DropdownSelect from '../../../inputs/DropdownSelect';
import { validateProfileForm } from '../../../../features/profile/ProfileValidation';
import { Checkbox } from '../../../inputs';
import SelectInstitutionDivision from '../institutionDivision/SelectInstitutionDivision';
import SelectDivision from '../institutionDivision/SelectDivision';

export const AdminUserForm = (props) => {
  const { initialValues, roles, statuses, disabled, formSupport, change } = props;
  const institutionDivision = initialValues.division;
  const { institutions, countries, states, institutionTypes } = formSupport;

  // this function is called when the user selects a new institution in the institution dropdown
  const handleInstitutionOnChange = (e, institutionId) => {
    // get list of all the divisions for the selected institutionId
    props.getDivisionFormSupport('divisions', institutionId);

    // set the selected division to null
    change('division.id', null);
  };

  const divisionForm = (disabled)
    ? (
      <AdminDivisionForm
        showInstitution
        toggleInfo
        disableFields
        initialValues={institutionDivision}
      />
    )
    : (
      <>
        <SelectInstitutionDivision
          fieldName="division.institution"
          fieldLabel="Institution"
          formName="adminUserForm"
          countries={countries}
          states={states}
          options={institutions.map(({ id, name }) => ({ value: id, item: name }))}
          selectedInstitution={institutionDivision.institution}
          dropdownOnChange={handleInstitutionOnChange}
          initialId={institutionDivision.id}
          change={change}
          institutionTypes={institutionTypes}
          initialValues={institutionDivision.institution}
          disableCreateNew
        />
        <SelectDivision
          formName="adminUserForm"
          fieldName="institutionDivision"
          change={change}
          countries={formSupport.countries}
          states={formSupport.states}
          divisions={props.divisions || formSupport.divisions} // from props.divisions if the institution changes
          institutions={formSupport.institutions}
          institutionTypes={formSupport.institutionTypes}
          disable={false}
          disableCreateNew
        />
      </>
    );
  return (
    <div>
      <UserForm
        titles={['Dr.', 'Miss', 'Mr.', 'Mrs.', 'Ms.', 'Prof.']}
        disableEmailAddress={disabled}
        disableFirstName={disabled}
        disableLastName={disabled}
        disableMiddleInitial={disabled}
        disableOccupation={disabled}
        disablePhone={disabled}
        disableTitle={disabled}
      />
      <hr />
      {divisionForm}
      <hr />
      <Field
        component={DropdownSelect}
        id="status"
        label="Status"
        placeholder="Status"
        name="status"
        options={statuses.map(s => ({ value: s, item: s }))}
      />
      <RolesForm
        userRoles={initialValues.roles}
        roles={roles}
      />
      <hr />
      <div style={{ marginTop: '30px' }} className="p-15">
        <h2>Option Out from Quarterly Email</h2>
        <Field
          component={Checkbox}
          name="optOut"
          id="optOut"
          label="Opted Out"
        />
      </div>
      <hr />
      <div style={{ marginTop: '30px' }} className="p-15">
        <h2>Is Internal User</h2>
        <Field
          component={Checkbox}
          name="isInternalUser"
          id="isInternalUser"
          label="Is Internal User"
        />
      </div>
    </div>
  );
};

AdminUserForm.propTypes = {
  roles: PropTypes.array,
  initialValues: PropTypes.object,
  statuses: PropTypes.array,
  disabled: PropTypes.bool,
  formSupport: PropTypes.object,
  change: PropTypes.func,
  divisions: PropTypes.array,
  getDivisionFormSupport: PropTypes.func,
};

export default reduxForm({
  form: 'adminUserForm',
  warn: validateProfileForm,
})(AdminUserForm);
