import {
  CART_SET_INITIAL_DATA,
  CART_DELETE_STUDY,
  CART_SET_PAGINATION,
  CART_DELETE_ITEMS,
  CART_SET_COLLAPSE_OPEN,
  CART_SET_SELECTED_REQUEST_TYPE,
  CART_RESET_STATE,
  REQUEST_TYPES,
} from '../../common/types';

const INITIAL_STATE = {
  [REQUEST_TYPES.DATA]: {
    studies: null, // array of data request studies in cart
    totalItems: 0, // total number of data request items
    pagination: {}, // pagination data for the data request items; each key corresponds to a different study id
  },
  [REQUEST_TYPES.BIOSPEC]: {
    studies: null, // array of biospec request studies in cart
    totalItems: 0, // total number of biospec request items
    pagination: {}, // pagination data for the biospec request items; each key corresponds to a different study id
  },
  selectedRequestType: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CART_SET_INITIAL_DATA:
      return { ...action.payload };
    case CART_DELETE_STUDY: {
      const { requestType, studies, totalItems, pagination, selectedRequestType } = action.payload;
      return {
        ...state,
        [requestType]: {
          studies,
          totalItems,
          pagination,
        },
        selectedRequestType,
      };
    }
    case CART_DELETE_ITEMS: {
      const { requestType, studyId, numItemsRemoved, page, items, itemsCanBeModified, collapseOpen } = action.payload;
      return {
        ...state,
        [requestType]: {
          ...state[requestType],
          totalItems: state[requestType].totalItems - numItemsRemoved,
          pagination: {
            ...state[requestType].pagination,
            [studyId]: {
              page,
              items,
              itemsCanBeModified,
              collapseOpen,
            },
          },
        },
      };
    }
    case CART_SET_PAGINATION: {
      const { requestType, studyId, page, items, collapseOpen } = action.payload;
      return {
        ...state,
        [requestType]: {
          ...state[requestType],
          pagination: {
            ...state[requestType].pagination,
            [studyId]: {
              ...state[requestType].pagination[studyId],
              page,
              items,
              collapseOpen,
            },
          },
        },
      };
    }
    case CART_SET_COLLAPSE_OPEN: {
      const { requestType, studyId, collapseOpen } = action.payload;
      return {
        ...state,
        [requestType]: {
          ...state[requestType],
          pagination: {
            ...state[requestType].pagination,
            [studyId]: {
              ...state[requestType].pagination[studyId],
              collapseOpen,
            },
          },
        },
      };
    }
    case CART_SET_SELECTED_REQUEST_TYPE:
      return {
        ...state,
        selectedRequestType: action.payload,
      };
    case CART_RESET_STATE:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};
