import {
  REPORT_SET_DATA,
  REPORT_SET_HISTOGRAM_DATA,
  REPORT_SET_FORM_SUPPORT,
  REPORT_SET_SUPPORT,
  REPORT_RESET_DATA,
  REPORT_RESET_STATE,
  REPORT_TYPES,
  REPORT_ACTIVITY_SET_COUNTS,
  REPORT_ACTIVITY_RESET,
  REPORT_ACTIVITY_SET_OVERVIEW,
} from '../../common/types';

export const initialState = {
  [REPORT_TYPES.REQUEST]: {},
  [REPORT_TYPES.USER]: {},
  [REPORT_TYPES.PORTFOLIO]: {},
  [REPORT_TYPES.OUTCOME]: {},
  [REPORT_TYPES.ACTIVITY]: {},
  formSupport: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REPORT_SET_DATA: {
      const { reportType, subReportType, data, tableSupport } = action.payload;
      return {
        ...state,
        [reportType]: {
          ...state[reportType],
          [subReportType]: {
            ...state[reportType][subReportType],
            ...tableSupport,
            data,
          },
        },
      };
    }
    case 'REPORT_SET_TOTAL': {
      const { reportType, subReportType, total, aggregateBy } = action.payload;
      return {
        ...state,
        [reportType]: {
          ...state[reportType],
          [subReportType]: {
            ...state[reportType][subReportType],
            totals: {
              ...state[reportType][subReportType]?.totals,
              [aggregateBy]: total,
            },
          },
        },
      };
    }
    case REPORT_ACTIVITY_SET_COUNTS: {
      const { data } = action.payload;
      return {
        ...state,
        activity: {
          ...state.activity,
          counts: data,
        },
      };
    }
    case REPORT_ACTIVITY_RESET: {
      return {
        ...state,
        activity: {},
      };
    }
    case REPORT_ACTIVITY_SET_OVERVIEW: {
      const { data, overviewType } = action.payload;
      return {
        ...state,
        activity: {
          ...state.activity,
          overview: {
            ...state.activity.overview,
            [overviewType]: data,
          },
        },
      };
    }
    case REPORT_SET_HISTOGRAM_DATA: {
      const { reportType, subReportType, interval, data } = action.payload;
      return {
        ...state,
        [reportType]: {
          ...state[reportType],
          [subReportType]: {
            ...state[reportType]?.[subReportType],
            histogramData: {
              ...state[reportType][subReportType]?.histogramData,
              [interval]: data,
            },
          },
        },
      };
    }
    case REPORT_SET_FORM_SUPPORT: {
      const { formSupportLabel, formSupportData } = action.payload;
      return {
        ...state,
        formSupport: {
          ...state.formSupport,
          [formSupportLabel]: formSupportData,
        },
      };
    }
    case REPORT_SET_SUPPORT: {
      const { reportType, subReportType, supportLabel, supportData } = action.payload;
      return {
        ...state,
        [reportType]: {
          ...state[reportType],
          [subReportType]: {
            ...state[reportType][subReportType],
            [supportLabel]: supportData,
          },
        },
      };
    }
    case REPORT_RESET_DATA:
      return {
        ...state,
        [action.payload]: {},
      };
    case REPORT_RESET_STATE:
      return { ...initialState };
    default:
      return state;
  }
};
