import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { FileUpload } from '../../../../../common/inputs';
import { validateUploadShipmentFulfillmentForm } from './AdminRequestValidator';

export const UploadFulfillmentForm = props => (
  <form onSubmit={props.handleSubmit}>
    <hr />
    <br />
    <div>
      Please upload the Shipment Fulfillment Form for this request.
    </div>
    <div className="form-group p-15">
      <Field
        component={FileUpload}
        name="uploadFulfillmentForm"
        id="shipment-fulfillment-form"
        label="Shipment Fulfillment Form"
        required
      />
    </div>
  </form>
);

UploadFulfillmentForm.propTypes = {
  handleSubmit: PropTypes.func,
};

export default reduxForm({
  form: 'uploadFulfillmentForm',
  validate: validateUploadShipmentFulfillmentForm,
})(UploadFulfillmentForm);
