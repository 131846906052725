import {
  ANNUAL_REPORT_SET_STATUS,
  ANNUAL_REPORT_SET_STEP_DATA,
  ANNUAL_REPORT_RESET_STATE,
  ANNUAL_REPORT_SET_FORM_SUPPORT,
} from '../../common/types';

const INITIAL_STATE = {
  data: null,
  requestType: null,
  status: null, // status is either 'Request Initiated', 'In Progress', 'Rejected', or 'Pending Approval'
  steps: null, // array of all the forms the user has to go through; array order is the order of the forms to complete
  currStepIndex: null, // index of the current form the user is on
  nextStepToCompleteIndex: null, // index of the next uncompleted form, the user cannot advance to any forms with index greated than nextStepToCompleteIndex
  reportYear: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ANNUAL_REPORT_SET_STATUS: // sets the studyName, requestType, status, hasUnresolvedConstraints, steps, currStepIndex, and nextStepToCompleteIndex fields
    case ANNUAL_REPORT_SET_STEP_DATA: // sets the currStepIndex and the step data and form support
    case ANNUAL_REPORT_SET_FORM_SUPPORT:
      return {
        ...state,
        ...action.payload,
      };
    case ANNUAL_REPORT_RESET_STATE:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};
