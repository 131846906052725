import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { RequiredTag } from '../../other/RequiredFields';
import './style.scss';

/**
 * This component renders a searchable select. This component was designed to work specifically with
 * redux-form, if you're not using redux-form it might be easier to simply use react-select instead.
 */
const SearchSelect = (props) => {
  const {
    input,
    id,
    label,
    placeholder,
    ariaLabel,
    disabled,
    required,
    options,
    multiple,
    className,
    meta: { touched, error, warning },
  } = props;

  // optional label
  const asterisk = required ? <RequiredTag /> : null;
  const renderLabel = label && (
    <label htmlFor={id}>
      {label}
      &nbsp;{asterisk}
    </label>
  );

  // set style if there is an error in the field
  let inputStyle = {};
  let errorStyle = {};
  let errorMessage = '';
  if (!disabled && touched && (error || warning)) {
    inputStyle = {
      outline: '',
      boxShadow: '0 0 5px rgb(213, 0, 0)',
      marginBottom: '0px',
      borderColor: 'rgb(213, 0, 0)',
      '&:hover': {
        borderColor: 'rgb(213, 0, 0)',
      },
    };
    errorStyle = { color: 'rgb(213, 0, 0)' };
    errorMessage = error || warning;
  }

  // override the default theme
  const customTheme = theme => ({
    ...theme,
    borderRadius: 0,
  });

  // override the default style
  const customStyles = {
    menu: provided => ({ ...provided, zIndex: 9999 }),
    control: base => ({
      ...base,
      ...inputStyle,
    }),
    groupHeading: base => ({
      ...base,
      color: 'black',
      fontWeight: 600,
      fontSize: '12px',
    }),
  };

  // In mobile mode react-select calls onChange and onBlur simultaneously which causes
  // a race condition. Adding the timeout ensures that the onChange function has updated the store.
  // Taken from here: https://www.firehydrant.io/blog/using-react-select-with-redux-form/
  const handleOnBlur = () => {
    setTimeout(() => input.onBlur(input.value), 1);
  };

  return (
    <div className={className}>
      {renderLabel}
      <Select
        {...input}
        onChange={value => input.onChange(value)}
        onBlur={handleOnBlur}
        id={id}
        options={options}
        placeholder={placeholder}
        aria-label={`${ariaLabel ? ariaLabel : ''} ${input.value.label ? `${input.value.label} is currently selected.` : 'None currently selected'}`}
        theme={customTheme}
        styles={customStyles}
        isMulti={multiple}
        isDisabled={disabled}
        menuContainerStyle={{ zIndex: 999 }}
      />
      <div style={errorStyle}>
        {errorMessage}
      </div>
    </div>
  );
};

SearchSelect.propTypes = {
  className: PropTypes.string,
  input: PropTypes.object,
  id: PropTypes.string.isRequired,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })),
    PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      })),
    })),
  ]).isRequired,
  label: PropTypes.string,
  ariaLabel: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  multiple: PropTypes.bool, // true if the Select should hold multiple values
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
  }),
};

SearchSelect.defaultProps = {
  placeholder: '',
  label: '',
  required: false,
  disabled: false,
  multiple: false,
};

export default SearchSelect;
