import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

/**
 * HOC that ensures the user is logged in. If the user is not logged in then they get redirected
 * to the login page.
 */
const AuthRoute = ({ component: C, authenticated, ...props }) => (
  <Route
    {...props}
    render={routeProps => (
      authenticated
        ? <C {...routeProps} />
        : <Redirect to={`/signin?redirect=${routeProps.location.pathname}${routeProps.location.search}`} />
    )}
  />
);

AuthRoute.propTypes = {
  component: PropTypes.func.isRequired,
  authenticated: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = ({ auth }) => ({ authenticated: auth.authenticated });

export default connect(mapStateToProps)(AuthRoute);
